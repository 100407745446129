import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Generate { 
  sessionId: string;
  prompt: string; 
  response: string;
  createTime: Date;
  status: {
    completeTime: Date;
    startTime: Date;
    state: string;
    updateTime: Date;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GenerateService {
  firestore: AngularFirestore;
  objs: AngularFirestoreCollection<Generate>;
  obj: AngularFirestoreDocument<Generate>;

  constructor(private afs: AngularFirestore) {
    this.firestore = afs;
  }

  findSessionId(sessionId: string) {
    if (sessionId != '') {
      this.objs = this.firestore.collection(`generate`, ref => ref.where('sessionId', '==', sessionId).orderBy("sessionId").orderBy("createTime"));
      return this.objs.snapshotChanges().pipe(map(changes => changes.map(c => ({data: c.payload.doc.data() as Generate}))));
    }
    return null;
  }

  createData(data: Generate): Promise<any> {
    return this.firestore.collection(`generate`).add(data);
  }

}
