import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Seller, SellerOption } from '../models/seller';
import { SellerService } from '../services/seller.service';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { finalize } from "rxjs/operators";
import { AuthenticationService } from '../services/authentication.service';
import { User } from "../models/user";
import { text1s } from '../../environments/environment';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { systemSettings } from '../../environments/environment';
import { ImageCropper, ImageCropperComponent } from './image-cropper.component';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

@Component({
  selector: 'seller-detail',
  templateUrl: './seller-detail.component.html',
  styleUrls: ['../styles.css']
})
export class SellerDetailComponent implements OnInit {

  @AutoUnsubscribe()
  seller$;
  @AutoUnsubscribe()
  user$;
  
  o: Seller;
  n = '';
  t = text1s;
  loading_file = false;
  waiting_action = false;
  currentUser: User;
  options = Object.keys(SellerOption).map(key => SellerOption[key]);
  companyInfo = {
    key: '',
    value: ''
  };
  deleteImages: string[] = new Array();
  i: UntypedFormGroup;

  imageCropper = {
    titel: 'Logo Bild Bearbeitung',
    imageChangedEvent: '',
    croppedImage: '',
    aspectRatio: 4 / 4,
  } as ImageCropper;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private metaTagService: Meta,
    private s: SellerService,
    private storage: AngularFireStorage,
    private authenticationService: AuthenticationService,
    private _snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.user$ = this.authenticationService.getCurrentUser().subscribe(o => this.currentUser = o);
    this.i = this.formBuilder.group({
      shopname: ['', [Validators.required,]],
      email: ['', [Validators.required,]],
      web: ['', []],
      companyform: ['', []],
      taxnumber: ['', []],
      imprint: ['', []],
      description: ['', []],
      keywords: ['', []],
      option: ['', []],
      firstname: ['', [Validators.required,]],
      lastname: ['', [Validators.required,]],
      street: ['', [Validators.required,]],
      house: ['', [Validators.required,]],
      zipcode: ['', [Validators.required,]],
      city: ['', [Validators.required,]],
      district: ['', []],
      country: ['', [Validators.required,]],
      telefon: ['', [Validators.required,]],
      deliveryAddressFirstname: ['', []],
      deliveryAddressLastname: ['', []],
      deliveryAddressStreet: ['', []],
      deliveryAddressHouse: ['', []],
      deliveryAddressZipcode: ['', []],
      deliveryAddressCity: ['', []],
      deliveryAddressDistrict: ['', []],
      deliveryAddressCountry: ['', []],
      deliveryAddressTelefon: ['', []],
    });

    this.seller$ = this.s.find('').subscribe(o => {
      this.o = o[0];
      if (!this.o) {
        this.o = new Seller();
        this.o.shopname = this.currentUser.userName;
        this.o.telefon = this.currentUser.phoneNumber;
        this.o.email = this.currentUser.emailId;
        this.o.uid = this.currentUser.uid;
      }
      this.setMeta();
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '800px',
      data: this.imageCropper
    });

    dialogRef.afterClosed().pipe(first()).subscribe(file => {
      if (file) {
        this.onFileSelected(file);
      }
    });
  }
  
  clickAddOption(obj: any) {
    if (this.companyInfo.key > "" && this.companyInfo.value > "") {
      this.o.infos.push(this.companyInfo);
      this._snackBar.open('✔️ ' + obj.key, obj.value, {
        duration: 4000,
      });
    }
    this.companyInfo = {
      key: '',
      value: ''
    };

  }

  clickRemoveOption(obj: any) {
    const indexOptions = this.o.infos.findIndex(val => val.key === obj.key);
    if (indexOptions > -1) {
      this.o.infos.splice(indexOptions, 1);
      this._snackBar.open('🗑️ ' + obj.key, obj.value, {
        duration: 4000,
      });
    }
  }

  clickUpdate(o: any) {
    this.i.markAllAsTouched();
    this.o.timestamp = new Date();
    this.o.keywords = this.o.city.toLowerCase()
      + ' '
      + this.o.district.toLowerCase()
      + ' '
      + this.o.description.toLowerCase();

    if (!this.i.invalid) {
      this.waiting_action = true;
      setTimeout(() => {

      const path = `${this.o.img[0]}_400x400`;
      this.storage.ref(path).getDownloadURL().subscribe(url => {
        if (url) {
          this.o.img[1] = url;
          this.s.save(o);
        }
      }, (err) => {
        console.log(err);
        this.s.save(o);
      }
      );
      this.deleteFilesfromTrash();
      this.waiting_action = false;
      this.router.navigate([this.n]);
    }, 1000);
    }
  }

  clickDelete(o: any) {
    this.waiting_action = true;
    setTimeout(() => {
      this.s.delete(o);
      this.deleteFilesfromTrash();
      this.waiting_action = false;
      this.router.navigate([this.n]);
  }, 1000);
  }

  moveImageToTrash(imgUrl) {
    if (!this.deleteImages.find(element => element == imgUrl)) {
      this.deleteImages.push(imgUrl);
    }
    this.o.img[0] = "";
    this.o.img[1] = "";
  }
  
  onFileSelected(file: any) {
    this.loading_file = true;
    const filepath = `${systemSettings.storagePrefix}/usersimg/${this.o.uid}/${file.name}`;
    this.o.img[0] = `${filepath}`;
    const task = this.storage.upload(filepath, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.storage.ref(filepath).getDownloadURL().subscribe(url => {
            if (url) {
              this.o.img[1] = url;
              this.loading_file = false;
            }
          }, (err) => {
            console.log(err);
            this.loading_file = false;
          }
          );
        })
      )
      .subscribe(objDownload => {
        if (objDownload) {
        }
      });
  }

  fileChangeEvent(event: any): void {
    this.imageCropper.imageChangedEvent = event;
    if (event.target.files[0]) {
      this.openDialog();
    }
}

  clickCancel() {
    this.router.navigate([this.n]);
  }

  deleteFilesfromTrash() {
    this.loading_file = true;
    this.deleteImages.forEach(downloadUrl => {
      if (downloadUrl != "") {
        this.storage.storage.refFromURL(downloadUrl).delete().then(val => {
          console.log(val);
        }, (err) => {
          console.log(err);
        }
        );
      }
    })

    this.loading_file = false;
  }

  setMeta(): void {
    this.titleService.setTitle(`${text1s.domain} | ${this.o.shopname}`);

    this.metaTagService.updateTag(
      { name: 'robots', content: 'noindex, nofollow' }
    );
    this.metaTagService.updateTag(
      { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: `${text1s.domain} ${this.o.shopname}` }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: `${text1s.domain} ${this.o.shopname}` }
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.i.controls; }
}