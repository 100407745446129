<!-- Begin Page Content -->
<main class="mt-1 pt-4">
    <div class="container dark-grey-text mt-5">
        <!--Navbar-->
        <nav *ngIf="o" class="navbar navbar-expand-lg navbar-dark mdb-color mt-3 mb-1">
            <span class="text-white">
                <a class="nav-link" (click)="clickCancel()"><i class="fas fa-reply"></i></a>
            </span>
            <!-- Collapse button -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicNav" aria-controls="basicNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Collapsible content -->
            <div class="collapse navbar-collapse" id="basicNav">
                <!-- Links -->
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link" (click)="clickUpdate()"><i class="fas fa-check"></i> Speichern <span *ngIf="waiting_action" class="spinner-border spinner-border-sm mr-1"></span>
                            <span class="sr-only">(current)</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="clickDelete()"><i class="fas fa-trash"></i> Löschen <span *ngIf="waiting_action" class="spinner-border spinner-border-sm mr-1"></span></a>
                    </li>
                </ul>
            </div>
            <!-- Collapsible content -->
        </nav>
        <!--/.Navbar-->

        <div class="row" *ngIf="o">
            <div class="col-lg-8 mb-4">
                <!--Card-->
                <div class="card">
                    <!--Card content-->
                    <div class="card-body">
                        <form [formGroup]="i" class="wt-form">
                            <fieldset class="border p-2">
                                <legend class="w-auto text-muted"><small>Newsletter</small></legend>
                                <mat-form-field>
                                    <input matInput placeholder="Name" name="o.name" [(ngModel)]="o.name" formControlName="name">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.name.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Status</mat-label>
                                    <mat-select formControlName="state" [(ngModel)]="o.state" name="o.state" matNativeControl required>
                                        <mat-option *ngFor="let state of states" [value]="state">
                                            {{state}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.state.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <textarea matInput placeholder="Template" name="o.template" [(ngModel)]="o.template" formControlName="template"></textarea>
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.template.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                            </fieldset>
                        </form>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
            </div>
        </div>
    </div>
</main>
<!-- /.container-fluid -->