import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { Title, Meta } from '@angular/platform-browser';
import { MatSort } from '@angular/material/sort';
import { Article } from '../models/article';
import { ArticleService } from '../services/article.service';
import { User } from "../models/user";
import { AuthenticationService } from '../services/authentication.service';
import { text1s } from '../../environments/environment';
import { UUID } from 'angular2-uuid';
import { first } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

@Component({
    selector: 'article-list',
    templateUrl: './article-list.component.html',
    styleUrls: ['../styles.css']
})
export class ArticleListComponent implements OnInit, AfterViewInit, OnDestroy {

    @AutoUnsubscribe()
    articles$;
    @AutoUnsubscribe()
    user$;

    n = 'c/article-detail';
    dataInput: any;
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['nr', 'articlename', 'group', 'state', 'timestamp'];
    currentUser: User;
    filterValue = '';
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private titleService: Title,
        private metaTagService: Meta,
        private router: Router,
        protected activatedRoute: ActivatedRoute,
        private s: ArticleService,
        private authenticationService: AuthenticationService,
    ) { };

    ngOnInit() {
        this.user$ = this.authenticationService.getCurrentUser().subscribe(o => {
            this.currentUser = o;
            this.articles$ = this.s.findUID(this.currentUser.uid).subscribe(o => {
                this.dataSource = new MatTableDataSource(o);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                if (this.filterValue) {
                    this.applyFilter(this.filterValue);
                }
            });
        });
        
        this.setMeta();
    }

    initArticle(): Article {
        const article = {
            id: UUID.UUID(),
            nr: '000',
            articlename: '',
            description: '',
            img_top_description: '',
            img_bottom_description: '',
            group: '',
            category: '',
            searchkey: '',
            state: '',
            options: new Array(),
            min_order_quantities: 0,
            max_order_quantities: 0,
            img: ['', ''],
            timestamp: new Date(),
            seller_shopname: '',
            seller_uid: ''
        } as Article;
        return article;
    }

    clickCopy(o: any) {
        let _article = o;
        _article.id = UUID.UUID();
        _article.img = ['', ''];
        _article.timestamp = new Date();

        _article.seller_uid = this.currentUser.uid;
        this.clickEdit(_article);
    }

    clickCreate() {
        let _article = this.initArticle();
        _article.seller_uid = this.currentUser.uid;
        this.clickEdit(_article);
    }

    clickEdit(o: any) {
        this.s.setSelected(o);
        this.router.navigate([this.n]);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    ngAfterViewInit() {
        if (this.s.filterValue != '' && this.s.filterValue != undefined) {
            this.filterValue = this.s.filterValue;
            this.s.filterValue = '';
        }
    }

    ngOnDestroy() {
        this.s.filterValue = this.filterValue;
    }

    setMeta(): void {
        this.titleService.setTitle(`${text1s.domain} | Artikel anlegen`);

        this.metaTagService.updateTag(
            { name: 'robots', content: 'noindex, nofollow' }
        );
        this.metaTagService.updateTag(
            { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
        );
        this.metaTagService.updateTag(
            { name: 'keywords', content: `${text1s.domain} Artikel anlegen` }
        );
        this.metaTagService.updateTag(
            { name: 'description', content: `${text1s.domain} Artikel anlegen` }
        );
    }
}
