import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { text1s } from '../../environments/environment';
import { SellerService } from '../services/seller.service';
import { ArticleService } from '../services/article.service';
import { Article, ArticleCategory } from '../models/article';
import { State } from './article-detail.component';
import { Seller } from '../models/seller';
import { first } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

@Component({
    selector: 'showpage',
    templateUrl: './showpage.component.html',
    styleUrls: ['../styles.css']
})
export class ShowpageComponent implements OnInit {

    @AutoUnsubscribe()
    activatedRoute$;
    @AutoUnsubscribe()
    activatedRouteFragment$;
    currentDate = new Date();
    searchkey = '';
    shopname = '';
    city = '';
    zipcode = '';
    seller_uid = '';
    articles: Article[];
    articleCategory = ArticleCategory;
    sellerAdmin: Seller;
    fragment = "";

    constructor(
        private titleService: Title,
        private metaTagService: Meta,
        private router: Router,
        protected activatedRoute: ActivatedRoute,
        private sellerService: SellerService,
        private articleService: ArticleService,
    ) {};


    getGroupArticles(group: string) {
        return this.articles.filter(article => article.group === group && article.state === State.active);
    }

    ngOnInit() {
        this.activatedRoute$ = this.activatedRoute.params.subscribe((params) => {
            if (params) {
                this.searchkey = params.searchkey;
                this.shopname = params.shopname;
                this.city = params.city;
                this.zipcode = params.zipcode;
                this.seller_uid = params.uid;
                this.getData(params.uid);

            }
        });
        this.activatedRouteFragment$ = this.activatedRoute.fragment.subscribe(fragment => {
            this.fragment = fragment;
        });
    }

    getData(sellerUID: string) {
        this.articleService.findUID(sellerUID).pipe(first()).subscribe(a => {
            if (a) {
                this.articles = a;
                this.setMeta();
            } else {
                this.router.navigate(['']);
            }
        });
        this.sellerService.find(sellerUID).pipe(first()).subscribe(seller => this.sellerAdmin = seller[0]);
    }

    
    setMeta(): void {
        let _searchkey = "home";

        if (this.searchkey && this.searchkey != "") {
            _searchkey = this.searchkey;
        }
        const _a = this.getGroupArticles(`sitemap-${_searchkey}`);
        this.titleService.setTitle(`${_a[0].articlename}`);

        this.metaTagService.updateTag(
            { name: 'robots', content: 'index, follow' }
        );
        this.metaTagService.updateTag(
            { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
        );
        this.metaTagService.updateTag(
            { name: 'keywords', content: `${this.searchkey} ${this.shopname} asiatisch vietnamesisches restaurant würzburg heidingsfeld` }
        );
        this.metaTagService.updateTag(
            { name: 'description', content: `${_a[0].searchkey}` }
        );
    }

}