import { UUID } from 'angular2-uuid';

export class Customer {

  id: string;
  customername: string;
  description: string;
  city: string;
  district: string;
  street: string;
  house: string;
  zipcode: string;
  country: string;
  telefon: string;
  email: string;
  firstname: string;
  lastname: string;
  text: string;
  option: string;
  img: string[];
  deliveryAddress: DeliveryAddress;
  timestamp: Date;
  uid: string;

  constructor(
    id = UUID.UUID(),
    customername = '',
    description = '',
    city = '',
    district = '',
    street = '',
    house = '',
    zipcode = '',
    country = '',
    telefon = '',
    email = '',
    firstname = '',
    lastname = '',
    text = '',
    option = '',
    img = ['anonym.jpg', '../../assets/img/pageimg/anonym.jpg'],
    deliveryAddress = {
      firstname: '',
      lastname: '',
      city: '',
      district: '',
      street: '',
      house: '',
      zipcode: '',
      country: '',
      telefon: '',
    } as DeliveryAddress,
    timestamp = new Date(),
    uid = '',
  ) {
    this.id = id;
    this.customername = customername;
    this.description = description;
    this.city = city;
    this.district = district;
    this.street = street;
    this.house = house;
    this.zipcode = zipcode;
    this.country = country;
    this.telefon = telefon;
    this.email = email;
    this.firstname = firstname;
    this.lastname = lastname;
    this.text = text;
    this.option = option;
    this.img = img;
    this.deliveryAddress = deliveryAddress;
    this.timestamp = timestamp;
    this.uid = uid;
  }

};

export interface DeliveryAddress {
  firstname: string;
  lastname: string;
  city: string;
  district: string;
  street: string;
  house: string;
  zipcode: string;
  country: string;
  telefon: string;
}

export enum CustomerOption {
  default = 'Standard',
  premium = 'Premium',
};
