export default class CommonFunctions {

    static getCookie(cName: string) {
        const name = cName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }


    static setCookie(cName: string, cValue: string, day?: number) {
        if (!day) {
            day = 1;
        }
        var expires_date = new Date();
        var seven_days = expires_date.getTime() + (day * 24 * 60 * 60 * 1000);
        expires_date.setTime(seven_days);

        document.cookie = (cName + '=' + cValue + ';path=/; expires=' + expires_date.toUTCString());
    }

    static deleteCookie(cName: string) {
        document.cookie = (cName + '=' + '' + ';path=/;' + 'expires=Thu, 01-Jan-01 00:00:01 GMT;');
    }

    static convertBase64ToFile(data, filename) {

        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

}