import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Article, ArticleCategory, ArticleOption } from '../models/article';
import { ArticleService } from '../services/article.service';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { first } from 'rxjs/operators';
import { text1s } from '../../environments/environment';
import { SellerService } from '../services/seller.service';
import { Seller } from '../models/seller';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { systemSettings } from '../../environments/environment';
import { ImageCropper, ImageCropperComponent } from './image-cropper.component';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

@Component({
  selector: 'article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['../styles.css']
})
export class ArticleDetailComponent implements OnInit {
  
  @AutoUnsubscribe()
  article$;
  @AutoUnsubscribe()
  seller$;
  
  o: Article;
  seller: Seller;
  n = 'c/article-list'
  t = text1s;
  categorys = Object.keys(ArticleCategory).map(key => ArticleCategory[key]);
  states = Object.keys(State).map(key => State[key]);
  option = this.initArticleOption();
  loading_file = false;
  waiting_action = false;
  downloadURL: Observable<string>;
  deleteImages: string[] = new Array();
  i: UntypedFormGroup;

  imageCropper = {
    titel: 'Artikel Bild Bearbeitung',
    imageChangedEvent: '',
    croppedImage: '',
    aspectRatio: 4 / 3,
  } as ImageCropper;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private metaTagService: Meta,
    private s: ArticleService,
    private sellerService: SellerService,
    private storage: AngularFireStorage,
    private _snackBar: MatSnackBar,
    private alertService: AlertService,
  ) {
  }
  
  ngOnInit() {

    this.i = this.formBuilder.group({
      nr: ['', [Validators.required,]],
      articlename: ['', [Validators.required,]],
      description: ['', []],
      img_top_description: ['', []],
      img_bottom_description: ['', []],
      group: ['', []],
      category: ['', [Validators.required,]],
      searchkey: ['', []],
      state: ['', [Validators.required,]],
      min_order_quantities: ['', [Validators.required,]],
      max_order_quantities: ['', [Validators.required,]],
    });

    this.seller$ = this.sellerService.find('').subscribe(seller => {
      if (seller) {
        this.seller = seller[0];
        this.article$ = this.s.getSelected().subscribe(article => {
          if (article) {
            this.o = article;
          } else {
            this.router.navigate([this.n]);
          }
        });
      } else {
        this.router.navigate([this.n]);
      }
    }
    ); 

    this.setMeta();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '800px',
      data: this.imageCropper
    });

    dialogRef.afterClosed().pipe(first()).subscribe(file => {
      if (file) {
        this.onFileSelected(file);
      }
    });
  }

  initArticleOption(): ArticleOption {
    const articleOption = {
      nr: '',
      description: '',
      price: 0
    } as ArticleOption;
    return articleOption;
  }

  clickAddOption(obj: ArticleOption) {
    if (this.option.nr > '' && this.option.description > '' && this.option.price > 0) {
      this.o.options.push(this.option);
      this._snackBar.open('✔️ ' + obj.description, obj.price.toString() + ' €', {
        duration: 4000,
      });
    }
    this.option = this.initArticleOption();;
  }

  clickRemoveOption(obj: ArticleOption) {
    const indexOptions = this.o.options.findIndex(val => val.nr === obj.nr);
    if (indexOptions > -1) {
      this.o.options.splice(indexOptions, 1);
      this._snackBar.open('🗑️ ' + obj.description, obj.price.toString() + ' €', {
        duration: 4000,
      });
    }
  }

  check() {
    let result = this.i.invalid;
    if (!result && this.o.options.length > 0) {
      result = true;
      for(let option of this.o.options){
        if (option.nr > '' && option.description > '' && option.price > 0) {
          result = false;
        } else {
          result = true;
          break;
        }
      }
    }
    
    if(result){
      this.alertService.snackbar(text1s.erro0001);
    }
    
    return result;
  }

  clickUpdate() {
    this.i.markAllAsTouched();
    this.o.timestamp = new Date();
    this.o.seller_shopname = this.seller.shopname;
    if (!this.check()) {
      this.waiting_action = true;
      setTimeout(() => {
        const path = `${this.o.img[0]}_400x400`;
        this.storage.ref(path).getDownloadURL().subscribe(url => {
          if (url) {
            this.o.img[1] = url;
            this.s.save(this.o);
          }
        }, (err) => {
          console.log(err);
          this.s.save(this.o);
        }
        );
        this.deleteFilesfromTrash();
        this.waiting_action = false;
        this.router.navigate([this.n]);
      }, 1000);
    }
  }

  clickCreate() {
    this.i.markAllAsTouched();
    this.o.timestamp = new Date();
    this.o.seller_shopname = this.seller.shopname;
    if (!this.check()) {
      this.waiting_action = true;
      setTimeout(() => {
        const path = `${this.o.img[0]}_400x400`;
        this.storage.ref(path).getDownloadURL().subscribe(url => {
          if (url) {
            this.o.img[1] = url;
            this.s.add(this.o);
          }
        }, (err) => {
          console.log(err);
          this.s.add(this.o);
        }
        );
        this.deleteFilesfromTrash();
        this.waiting_action = false;
        this.router.navigate([this.n]);
      }, 1000);
    }
  }

  clickDelete() {
    this.waiting_action = true;
    setTimeout(() => {
      this.s.delete(this.o);
      this.deleteFilesfromTrash();
      this.waiting_action = false;
      this.router.navigate([this.n]);
    }, 1000);
  }

  moveImageToTrash(imgUrl) {
    if (!this.deleteImages.find(element => element == imgUrl)) {
      this.deleteImages.push(imgUrl);
    }
    this.o.img[0] = "";
    this.o.img[1] = "";
  }
  
  onFileSelected(file: any) {
    this.loading_file = true;
    const filepath = `${systemSettings.storagePrefix}/usersimg/${this.o.seller_uid}/${file.name}`;
    this.o.img[0] = `${filepath}`;
    const task = this.storage.upload(filepath, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.storage.ref(filepath).getDownloadURL().subscribe(url => {
            if (url) {
              this.o.img[1] = url;
              this.loading_file = false;
            }
          }, (err) => {
            console.log(err);
            this.loading_file = false;
          }
          );
        })
      )
      .subscribe(objDownload => {
        if (objDownload) {
        }
      });
  }

  fileChangeEvent(event: any): void {
    this.imageCropper.imageChangedEvent = event;
    if (event.target.files[0]) {
      this.openDialog();
    }
  }

  clickCancel() {
    this.router.navigate([this.n]);
  }

  deleteFilesfromTrash() {
    this.loading_file = true;
    this.deleteImages.forEach(downloadUrl => {
      if (downloadUrl != "") {
        this.storage.storage.refFromURL(downloadUrl).delete().then(val => {
          console.log(val);
        }, (err) => {
          console.log(err);
        }
        );
      }
    })

    this.loading_file = false;
  }

  setMeta(): void {
    this.titleService.setTitle(`${text1s.domain} | Artikel anlegen`);

    this.metaTagService.updateTag(
      { name: 'robots', content: 'noindex, nofollow' }
    );
    this.metaTagService.updateTag(
      { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: `${text1s.domain} Artikel anlegen` }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: `${text1s.domain} Artikel anlegen` }
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.i.controls; }
}

export enum State {
  active = 'aktiv',
  inactive = 'inaktiv',
};