import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { throwError as _throw, of } from 'rxjs';
import { Article } from '../models/article';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';
import { systemSettings } from '../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ArticleService {

    objs: AngularFirestoreCollection<Article>;
    obj: AngularFirestoreDocument<Article>;
    selectedObj: Article;
    filterValue: string;
    private user: User;

    constructor(
        private firestore: AngularFirestore,
        private authService: AuthenticationService,
    ) {
        this.authService.getCurrentUser().pipe(first()).subscribe(user => this.user = user);
    }

    setSelected(o: Article) {
        this.selectedObj = o;
    }

    getSelected() {
        return of(this.selectedObj);
    }

    findUID(uid: string) {
        if (uid == '') {
            uid = this.user.uid;
        }
        this.objs = this.firestore.collection(`${systemSettings.collectionPrefix}_articles`, ref => ref.where('seller_uid', '==', uid).orderBy("nr").orderBy("articlename"));
        return this.objs.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                id: c.payload.doc.id,
                nr: c.payload.doc.data().nr,
                articlename: c.payload.doc.data().articlename,
                description: c.payload.doc.data().description,
                img_top_description: c.payload.doc.data().img_top_description,
                img_bottom_description: c.payload.doc.data().img_bottom_description,
                group: c.payload.doc.data().group,
                category: c.payload.doc.data().category,
                searchkey: c.payload.doc.data().searchkey,
                state: c.payload.doc.data().state,
                options: c.payload.doc.data().options,
                min_order_quantities: c.payload.doc.data().min_order_quantities,
                max_order_quantities: c.payload.doc.data().max_order_quantities,
                img: c.payload.doc.data().img,
                seller_shopname: c.payload.doc.data().seller_shopname,
                seller_uid: c.payload.doc.data().seller_uid,
                timestamp: c.payload.doc.data().timestamp,
            }))));
    }

    save(data: any) {
        if (data.seller_uid == '') {
            data.seller_uid = this.user.uid;
        }
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_articles`)
            .doc(data.id)
            .set(data);
    };

    add(data: any) {
        if (data.seller_uid == '') {
            data.seller_uid = this.user.uid;
        }
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_articles`)
            .add(data);
    };

    delete(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_articles`)
            .doc(data.id)
            .delete();
    }
}