import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Newsletter } from '../models/newsletter';
import { NewsletterService } from '../services/newsletter.service';
import { Title, Meta } from '@angular/platform-browser';
import { Observable } from "rxjs";
import { first } from 'rxjs/operators';
import { text1s } from '../../environments/environment';
import { SellerService } from '../services/seller.service';
import { Seller } from '../models/seller';
import { systemSettings } from '../../environments/environment';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

@Component({
  selector: 'newsletter-detail',
  templateUrl: './newsletter-detail.component.html',
  styleUrls: ['../styles.css']
})
export class NewsletterDetailComponent implements OnInit {
  
  @AutoUnsubscribe()
  newsletter$;
  @AutoUnsubscribe()
  seller$;
  
  o: Newsletter;
  seller: Seller;
  n = 'c/newsletter-list'
  t = text1s;
  states = Object.keys(State).map(key => State[key]);
  loading_file = false;
  waiting_action = false;
  downloadURL: Observable<string>;
  deleteImages: string[] = new Array();
  i: UntypedFormGroup;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private metaTagService: Meta,
    private s: NewsletterService,
    private sellerService: SellerService,
  ) {
  }
  
  ngOnInit() {
    this.i = this.formBuilder.group({
      name: ['', [Validators.required]],
      template: ['', [Validators.required]],
      state: ['', [Validators.required,]],
    });
    this.seller$ = this.sellerService.find('').subscribe(seller => {
      if (seller) {
        this.seller = seller[0];
        this.newsletter$ = this.s.getSelected().subscribe(newsletter => {
          if (newsletter) {
            this.o = newsletter;
          } else {
            this.router.navigate([this.n]);
          }
        });
      } else {
        this.router.navigate([this.n]);
      }
    }
    ); 
    this.setMeta();
  }

  clickUpdate() {
    this.i.markAllAsTouched();
    this.o.timestamp = new Date();
    if (!this.i.invalid) {
      this.waiting_action = true;
      if (this.o.state == 'send' || this.o.state == 'test') {
        this.s.save(this.o);
      }
      setTimeout(() => {
        this.o.state = 'wait';
        this.s.save(this.o);
        this.waiting_action = false;
        this.router.navigate([this.n]);
      }, 3000);
    }
  }

  clickDelete() {
    this.waiting_action = true;
    setTimeout(() => {
      this.s.delete(this.o);
      this.waiting_action = false;
      this.router.navigate([this.n]);
    }, 1000);
  }

  clickCancel() {
    this.router.navigate([this.n]);
  }

  setMeta(): void {
    this.titleService.setTitle(`${text1s.domain} | Newsletter`);

    this.metaTagService.updateTag(
      { name: 'robots', content: 'noindex, nofollow' }
    );
    this.metaTagService.updateTag(
      { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: `${text1s.domain} Newsletter` }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: `${text1s.domain} Newsletter` }
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.i.controls; }
}

export enum State {
  active = 'send',
  inactive = 'wait',
  test = 'test',
};