import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { text1s } from '../../environments/environment';
import { SellerService } from '../services/seller.service';
import { NewsletterUserService } from '../services/newsletterUser.service';
import { NewsletterUser } from '../models/newsletterUser';
import { Seller } from '../models/seller';
import { first } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

@Component({
    selector: 'emailconfirm',
    templateUrl: './emailconfirm.component.html',
    styleUrls: ['../styles.css']
})
export class EmailconfirmComponent implements OnInit {
   
    @AutoUnsubscribe()
    activatedRoute$;
    emailconfirm = '';
    fragment = "";
    sellerAdmin: Seller;
    newsletterUser: NewsletterUser;
   
    constructor(
        private titleService: Title,
        private metaTagService: Meta,
        private router: Router,
        protected activatedRoute: ActivatedRoute,
        private sellerService: SellerService,
        private newsletterUserService: NewsletterUserService,
    ) {};

    ngOnInit() {
        this.activatedRoute$ = this.activatedRoute.params.subscribe((params) => {
            if (params) {
                this.emailconfirm = params.emailconfirm;
                this.getData(this.emailconfirm);
            }
        });
    }

    getData(emailconfirm: string) {
        this.newsletterUserService.findID(emailconfirm).pipe(first()).subscribe(u => {
            if (u) {
                this.newsletterUser = u[0];
                this.sellerService.find(this.newsletterUser.seller_uid).pipe(first()).subscribe(seller => this.sellerAdmin = seller[0]);
                this.setMeta();
            } else {
                this.router.navigate(['']);
            }
        });
    }

    clickConfirm(confirm){
        this.newsletterUser.state = confirm;
        this.newsletterUserService.save(this.newsletterUser);
    }
    
    setMeta(): void {

        this.titleService.setTitle(`Email bestätigen`);

        this.metaTagService.updateTag(
            { name: 'robots', content: 'index, nofollow' }
        );
        this.metaTagService.updateTag(
            { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
        );
        this.metaTagService.updateTag(
            { name: 'keywords', content: `Email bestätigen` }
        );
        this.metaTagService.updateTag(
            { name: 'description', content: `Email bestätigen` }
        );
    }

}