import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject({});
    constructor(private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messages.pipe(first()).subscribe(
            (_messaging) => {
                //_messaging.onMessage = _messaging.onMessage.bind(_messaging);
                //_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }
    requestPermission() {
        this.angularFireMessaging.requestToken.pipe(first()).subscribe(
            (token) => {
                // console.log(token);
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }
    receiveMessage() {
        this.angularFireMessaging.messages.pipe(first()).subscribe(
            (payload) => {
                console.log("new message received. ", payload);
                this.currentMessage.next(payload);
            })
    }
}