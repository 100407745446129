import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { text1s } from '../../environments/environment';
import { Customer } from '../models/customer';
import { CustomerService } from '../services/customer.service';


@Component({
	selector: 'register.component',
	templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit {
	registerForm: UntypedFormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private alertService: AlertService,
		private s: CustomerService,
	) {

	}

	ngOnInit() {
		this.registerForm = this.formBuilder.group({
			displayName: ['', Validators.required],
			email: ['', Validators.required],
			password: ['', Validators.required]
		});
		// get return url from route parameters or default to '/'
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
	}

	// convenience getter for easy access to form fields
	get f() { return this.registerForm.controls; }

	async onSubmit() {
		this.submitted = true;

		// reset alerts on submit
		this.alertService.clear();

		// stop here if form is invalid
		if (this.registerForm.invalid) {
			return;
		}
		this.loading = true;
		await this.authenticationService.createUserWithEmailAndPassword(this.f.email.value, this.f.password.value)
			.then(
				result => {
					if (!result.user?.emailVerified) {
						this.alertService.success(text1s.info0002);
						result.user?.sendEmailVerification();
						result.user?.updateProfile({
							displayName: this.f.displayName.value,
							photoURL: '',
						});
						setTimeout(() => {
							this.createCustomer(result);
							this.router.navigate([this.returnUrl]);
						}, 4000);

					}
				}).catch((err) => {
					this.alertService.error(err.message);
					this.loading = false;
				});
	}

	createCustomer(i: any) {

		let customer = new Customer();
		customer.customername = this.f.displayName.value;
		customer.description = this.f.displayName.value;
		customer.email = i.user.email;
		customer.uid = i.user.uid;

		this.s.add(Object.assign({}, customer));
	}
}
