import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { Title, Meta } from '@angular/platform-browser';
import { MatSort } from '@angular/material/sort';
import { Newsletter } from '../models/newsletter';
import { NewsletterService } from '../services/newsletter.service';
import { User } from "../models/user";
import { AuthenticationService } from '../services/authentication.service';
import { text1s } from '../../environments/environment';
import { UUID } from 'angular2-uuid';
import { first } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

@Component({
    selector: 'newsletter-list',
    templateUrl: './newsletter-list.component.html',
    styleUrls: ['../styles.css']
})
export class NewsletterListComponent implements OnInit, AfterViewInit, OnDestroy {

    @AutoUnsubscribe()
    newsletters$;
    @AutoUnsubscribe()
    user$;

    n = 'c/newsletter-detail';
    dataInput: any;
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['name', 'state', 'timestamp'];
    currentUser: User;
    filterValue = '';
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private titleService: Title,
        private metaTagService: Meta,
        private router: Router,
        protected activatedRoute: ActivatedRoute,
        private s: NewsletterService,
        private authenticationService: AuthenticationService,
    ) { };

    ngOnInit() {
        this.user$ = this.authenticationService.getCurrentUser().subscribe(o => {
            this.currentUser = o;
            this.newsletters$ = this.s.findUID(this.currentUser.uid).subscribe(o => {
                this.dataSource = new MatTableDataSource(o);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                if (this.filterValue) {
                    this.applyFilter(this.filterValue);
                }
            });
        });
        
        this.setMeta();
    }

    initNewsletter(): Newsletter {
        const newsletter = {
            id: UUID.UUID(),
            name: '',
            template: '',
            state: '',
            timestamp: new Date(),
            seller_uid: ''
        } as Newsletter;
        return newsletter;
    }

    clickCopy(o: any) {
        let _newsletter = o;
        _newsletter.id = UUID.UUID();
        _newsletter.timestamp = new Date();
        _newsletter.seller_uid = this.currentUser.uid;
        this.clickEdit(_newsletter);
    }

    clickCreate() {
        let _newsletter = this.initNewsletter();
        _newsletter.seller_uid = this.currentUser.uid;
        this.clickEdit(_newsletter);
    }

    clickEdit(o: any) {
        this.s.setSelected(o);
        this.router.navigate([this.n]);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    ngAfterViewInit() {
        if (this.s.filterValue != '' && this.s.filterValue != undefined) {
            this.filterValue = this.s.filterValue;
            this.s.filterValue = '';
        }
    }

    ngOnDestroy() {
        this.s.filterValue = this.filterValue;
    }

    setMeta(): void {
        this.titleService.setTitle(`${text1s.domain} | Newsletter`);

        this.metaTagService.updateTag(
            { name: 'robots', content: 'noindex, nofollow' }
        );
        this.metaTagService.updateTag(
            { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
        );
        this.metaTagService.updateTag(
            { name: 'keywords', content: `${text1s.domain} Newsletter` }
        );
        this.metaTagService.updateTag(
            { name: 'description', content: `${text1s.domain} Newsletter` }
        );
    }
}
