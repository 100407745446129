<div class="pt-5">&nbsp;</div>
<main>
    <div class="container mt-2 mb-5">
        <h1 class="mt-3 h1 grey-text">Genussguide</h1>
        <h5><strong>Einfach gefragt. Köstlich beraten.</strong></h5>
        <div class="row p-2">
            <div class="col-12 p-2 d-flex flex-wrap gap-2">
                <button mat-raised-button color="primary" *ngFor="let prompt of buttonPrompts" (click)="submitPromptWithQuetion(prompt)" class="d-flex align-items-center m-1">{{ prompt }}</button>
            </div>
        </div>
        
        <p class="mt-2 mb-0">Stellen Sie Ihre Frage...</p>
        <div *ngIf="responses && responses.length > 0" class="mt-4">
            <div *ngFor="let response of responses">
                <div class="rounded d-flex align-items-min-height" style="background-color: #f5f5f5;">
                    <p class="px-2 pt-3 mb-0" [innerHTML]="setMarked(response.prompt)"></p>
                </div>  
                <div class="p-2 mt-2">
                    <p class="mb-0" [innerHTML]="setMarked(response.response)"></p>
                </div>
            </div>
        </div>            
        <div *ngIf="!isSending" class="p-3 rounded d-flex align-items-center w-100" style="background-color: #f5f5f5;">
            <input
            type="text"
            class="form-control me-2"
            placeholder="..."
            name="userPrompt"
            [(ngModel)]="userPrompt"
            (keyup.enter)="submitPrompt()"
            />
            <button mat-raised-button (click)="submitPrompt()">
                <mat-icon class="pulse" color="primary">assistant</mat-icon>
            </button>
        </div>
        <div *ngIf="isSending" class="p-3 rounded d-flex align-items-center w-100">
            <mat-spinner diameter="30"></mat-spinner>
            <span class="ml-2">...</span>
        </div>
    </div>
</main>