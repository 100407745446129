import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { CustomerService } from '../services/customer.service';
import { SellerService } from '../services/seller.service';
import { User } from '../models/user';
import { Customer } from '../models/customer';
import { Seller } from '../models/seller';
import { MatSnackBar as MatSnackBar, MatSnackBarRef as MatSnackBarRef, MAT_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router, NavigationEnd, ActivatedRoute, } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { text1s, systemSettings } from '../../environments/environment';
import CommonFunctions from "../shared/common.functions";
import { ArticleService } from '../services/article.service';
import { Article } from '../models/article';
import { State } from './article-detail.component';
import { UUID } from 'angular2-uuid';
import { first } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { Showinfo } from './../models/showinfo';
import { ShowinfoComponent } from './showinfo.component';
import { MatDialog } from '@angular/material/dialog';
import { NewsletterUser } from '../models/newsletterUser';
import { NewsletterUserService } from '../services/newsletterUser.service';

@Component({
    selector: 'basic',
    templateUrl: 'basic.component.html',
    styleUrls: ['../styles.css']
})
export class BasicComponent implements OnInit {

    @AutoUnsubscribe()
    article$;
    @AutoUnsubscribe()
    seller$;
    @AutoUnsubscribe()
    activatedRouteFragment$;
    
    currentDate = new Date();
    token: any = window.location.hostname;
    uid = '';
    busine_text = '';
    busine_newsletter = '';
    currentUser: User;
    customer: Customer;
    seller: Seller;
    sellerAdmin: Seller;
    articles: Article[];
    iNewsletter: FormGroup;
    @ViewChild('formDirectiveNewsletter') private formDirectiveNewsletter: FormGroupDirective;
    i: FormGroup;
    @ViewChild('formDirective') private formDirective: FormGroupDirective;

    constructor(
        private router: Router,
        private _snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private customerService: CustomerService,
        private sellerService: SellerService,
        private articleService: ArticleService,
        private formBuilder: FormBuilder,
        private formBuilderNewsletter: FormBuilder,
        public dialog: MatDialog,
        private newsletterUserService: NewsletterUserService,
        protected activatedRoute: ActivatedRoute,
    ) {
        if (!CommonFunctions.getCookie(systemSettings.cookieName))
            this._snackBar.openFromComponent(BasicInfoComponent, {
                duration: 1200 * 1000,
            });
    }

    async getUid() {
        return await this.sellerService.getSellerUid(systemSettings.operator);
    }

    clickShowPage(searchkey: string) {
        this.router.navigate(['speisekarte', searchkey, this.sellerAdmin.shopname, this.sellerAdmin.city, this.sellerAdmin.zipcode, this.sellerAdmin.uid], {fragment: searchkey});
    }

    clickShowPageAGB(searchkey: string) {
        this.router.navigate(['agb', searchkey, this.sellerAdmin.shopname, this.sellerAdmin.city, this.sellerAdmin.zipcode, this.sellerAdmin.uid], {fragment: searchkey});
    }

    getGroupArticles(group: string) {
        return this.articles.filter(article => article.group === group && article.state === State.active);
    }

    ngOnInit() {

        this.iNewsletter = this.formBuilderNewsletter.group({
            busine_newsletter: ['', []]
        });

        this.i = this.formBuilder.group({
            busine_text: ['', []]
        });

        this.getUid().then(seller => {
            this.seller$ = this.sellerService.find(seller[0].uid).subscribe(seller => {
                this.sellerAdmin = seller[0];
            });
            this.article$ = this.articleService.findUID(seller[0].uid).subscribe(articles => {
                this.articles = articles;
            });
        });

        this.currentUser = this.authenticationService.getCurrentUser().getValue();
        this.uid = CommonFunctions.getCookie(this.token);
        if (this.currentUser.uid != null) {
            this.uid = this.currentUser.uid;
        }
        if (this.uid != '') {
            this.customerService.find(this.uid).pipe(first()).subscribe(
                o => {
                    this.customer = o[0];
                }
            );
            this.sellerService.find(this.uid).pipe(first()).subscribe(
                o => {
                    this.seller = o[0];
                }
            );
        }

        this.router.events
            .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).pipe(first())
            .subscribe(() => {
                window.scrollTo(
                    {
                        top: 0,
                        left: 0,
                        behavior: 'auto'
                    }
                );
            });

            this.activatedRouteFragment$ = this.activatedRoute.fragment.subscribe(fragment => {
                if (fragment) {
                    setTimeout(() => {
                        this.scroll(fragment)
                    }, 1000);
                }
            });

    }

    scroll(id) {
        let el = document.getElementById(id);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
        
    }

    initNewsletterUser(_email): NewsletterUser {
        const newsletterUser = {
            id: UUID.UUID(),
            email: _email,
            state: 'inactive',
            timestamp: new Date(),
            seller_uid: systemSettings.seller_uid
        } as NewsletterUser;
        return newsletterUser;
    }

    clickSendNewsletter() {
        this.iNewsletter.markAllAsTouched();
        if (!this.iNewsletter.invalid && this.busine_newsletter > '' && this.busine_newsletter.length > 5) {
            this.newsletterUserService.save(this.initNewsletterUser(this.busine_newsletter));
            let showinfo = {
                title: 'Vielen Dank für Ihr Interesse an unserem Newsletter!',
                texts: ['Wir freuen uns, Sie in unserem Verteiler zu haben. Um Ihre Anmeldung abzuschließen, bitten wir Sie, Ihre E-Mail-Adresse zu bestätigen.'],
                img: ''
            } as Showinfo;
            const dialogRef = this.dialog.open(ShowinfoComponent, {
                width: "390px",
                disableClose: true,
                data: showinfo
            });
           dialogRef.afterClosed().pipe(first()).subscribe(() => {
               this.busine_newsletter = ""; 
                this.formDirectiveNewsletter.resetForm();
            });             
        }    
    }

    clickSendInfo() {
        this.i.markAllAsTouched();
        if (!this.i.invalid && this.busine_text > '' && this.busine_text.length > 5) {
            this.sellerService.sendmail(this.busine_text);
            let showinfo = {
                title: 'Vielen Dank für Ihre Nachricht',
                texts: ['Ihr Feedback ist wertvoll für uns.'],
                img: ''
            } as Showinfo;
            const dialogRef = this.dialog.open(ShowinfoComponent, {
                width: "390px",
                disableClose: true,
                data: showinfo
            });
           dialogRef.afterClosed().pipe(first()).subscribe(() => {
               this.busine_text = ""; 
                this.formDirective.resetForm();
            });             
        }    
    }


    logout() {
        this.authenticationService.logout();
    }

     // convenience getter for easy access to form fields
     get f() { return this.i.controls; }
     get fNewsletter() { return this.iNewsletter.controls; }

}

@Component({
    selector: 'basic-snackbar-info-component',
    templateUrl: 'basic-snackbar-info.component.html',
    styleUrls: ['../styles.css'],
})
export class BasicInfoComponent {

    address: any;

    clickShowPageAGB() {
        this.router.navigate([text1s.link_agb1]);
    }

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public snackBarRef: MatSnackBarRef<BasicInfoComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {
        this.authenticationService.getIPAddress().pipe(first()).subscribe(a => this.address = a);
    }

    setCookie() {
        //const curDate = this.datepipe.transform(Date.now(), 'yyyy/MM/dd');
        //CommonFunctions.setCookie("__" + curDate, this.address.ip + " || " + this.datepipe.transform(Date.now(), 'yyyy/MM/dd, hh:mm:ss'));

        // const curDate = this.datepipe.transform(Date.now(), 'yyyy/MM/dd');
        // CommonFunctions.setCookie("__" + curDate, this.address.ip, 30);

        CommonFunctions.setCookie(systemSettings.cookieName, UUID.UUID(), 30);
    }
}
