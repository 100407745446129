<div *ngIf="fragment" id="carousel-show-1z" class="carousel slide carousel-fade pt-4" data-ride="carousel">
    <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
            <div class="view" style="background-image: url('../../assets/img/pageimg/{{fragment}}.jpg'); background-repeat: no-repeat; background-size: cover;">
                <div class="mask rgba-black-strong d-flex justify-content-center align-items-center">
                    <div class="text-center white-text mx-5 wow fadeIn">
                        <img *ngIf="sellerAdmin" src="{{sellerAdmin.img[1]}}" class="img-profile-150 mt-5" alt="{{sellerAdmin.shopname}}">
                        <mat-spinner *ngIf="!sellerAdmin" class="img-profile-150 mt-5"></mat-spinner>
                        <h3 class="mt-3">
                            <strong>Authentische Straßenküche Vietnams</strong>
                        </h3>
                        <h4>
                            <strong><a href="tel:093168084329" target="_blank"><span class="wiggle"><i class="fa fa-phone"></i></span> (0931) 6808 4329</a></strong>
                        </h4>
                        <small>Mo. - Fr. von 11:00 - 20:30 Uhr</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!fragment" class="pt-5">&nbsp;</div>
<main>
    <div class="container text-center">
        <section *ngIf="newsletterUser">
            <h1 class="my-4 h1 grey-text">Email bestätigen</h1>
            <div class="row">
                <div class="col-lg-12 mb-3 text-left">
                    <p class="text-muted">Ihre Email: {{newsletterUser.email}}</p>
                    <div class="text-muted mb-2">Bitte bestätigen Sie, dass Sie regelmäßig per E-Mail die neuesten Angebote und Restaurant-Updates von NGOAN Streetfood erhalten möchten. Sie haben jederzeit die Möglichkeit, sich von unserem Newsletter abzumelden. Hierfür klicken Sie
                        einfach auf den Abmeldelink am Ende des Newsletters. Sobald Sie sich abgemeldet haben, wird Ihre E-Mail-Adresse automatisch innerhalb von 24 Stunden aus unserem Verteiler entfernt. Ihre Zustimmung zur Verarbeitung Ihrer personenbezogenen
                        Daten basiert auf Artikel 6 Absatz 1 Satz 1 lit. a DSGVO sowie § 7 Absatz 2 Nr. 2 bzw. Absatz 3 UWG. Weitere Informationen finden Sie in unserer Datenschutzerklärung.</div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span *ngIf="newsletterUser.state == 'inactive'">
                        <a (click)="clickConfirm('active');">
                            <button mat-raised-button color="primary">
                                Bestätigen
                            </button>
                        </a>
                    </span>
                    <span *ngIf="newsletterUser.state == 'active'">
                        <a (click)="clickConfirm('inactive');">
                            <button mat-raised-button>
                                Abmelden
                            </button>
                        </a>
                    </span>
                </div>
            </div>
            <hr class="my-3">
        </section>
    </div>
</main>