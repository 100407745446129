<!-- Begin Page Content -->
<main class="mt-1 pt-4">
    <div class="container dark-grey-text mt-5">
        <!--Navbar-->
        <nav class="navbar navbar-expand-lg navbar-dark mdb-color mt-3 mb-1">
            <!-- Suche -->
            <form class="form-inline">
                <div class="md-form my-0">
                    <input name="filterValue" [(ngModel)]="filterValue" type="text" (keyup)="applyFilter($event.target.value)" class="form-control mr-sm-2" placeholder="Suche..." aria-label="Search">
                </div>
            </form>
            <!-- Collapse button -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicNav" aria-controls="basicNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
            <!-- Collapsible content -->
            <div class="collapse navbar-collapse" id="basicNav">
                <!-- Links -->
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link" (click)="clickCreate()"><i class="fas fa-copy"></i> Erstellen
                            <span class="sr-only">(current)</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- Collapsible content -->
        </nav>
        <!--/.Navbar-->

        <!-- DataTales -->
        <section class="text-center">
            <div class="wow fadeIn">
                <div class="table-responsive card">
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                        <!-- Column -->
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
                            <mat-cell *matCellDef="let e"><span (click)="clickEdit(e)" class="fakelink">{{e.email}}</span></mat-cell>
                        </ng-container>
                        <!-- Column -->
                        <ng-container matColumnDef="state">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                            <mat-cell *matCellDef="let e"><span (click)="clickEdit(e)" class="fakelink">{{e.state}}</span></mat-cell>
                        </ng-container>
                        <!-- Column -->
                        <ng-container matColumnDef="timestamp">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Aw.</mat-header-cell>
                            <mat-cell *matCellDef="let e"><span (click)="clickCopy(e)" class="fakelink"><i class="far fa-clone"></i></span></mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </div>
        </section>

        <!--Pagination-->
        <nav class="d-flex justify-content-center wow fadeIn">
            <ul class="pagination pg-blue">
                <mat-paginator [hidePageSize]="true" [pageSizeOptions]="[50, 100]" showFirstLastButtons></mat-paginator>
            </ul>
        </nav>
        <!--Pagination-->

    </div>
</main>
<!-- /.container-fluid -->