import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AlertComponent } from './components/alert.component';
import { CarouselStartComponent } from './components/carousel-start.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { LoginComponent } from './components/login.component';
import { PasswordResetComponent } from './components/passwordReset.component';
import { RegisterComponent } from './components/register.component';
import { RegisterSellerComponent } from './components/registerSeller.component';
import { ImageCropperComponent } from './components/image-cropper.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BasicComponent } from './components/basic.component';
import { ShowinfoComponent } from './components/showinfo.component';
import { StartComponent } from './components/start.component';
import { ShowpageComponent } from './components/showpage.component';
import { AssistantComponent } from './components/assistant.component';
import { LogoutComponent } from './components/logout.component';
import { ArticleShowAddInfoListComponent } from './components/articleShowAddInfo-list.component';
import { ArticleListComponent } from './components/article-list.component';
import { ArticleDetailComponent } from './components/article-detail.component';
import { NewsletterUserListComponent } from './components/newsletterUser-list.component';
import { NewsletterUserDetailComponent } from './components/newsletterUser-detail.component';
import { EmailconfirmComponent } from './components/emailconfirm.component';
import { NewsletterListComponent } from './components/newsletter-list.component';
import { NewsletterDetailComponent } from './components/newsletter-detail.component';
import { SellerDetailComponent } from './components/seller-detail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FireBaseConfig } from "./../environments/firebase.config";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule as MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule as MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule as MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, AsyncPipe } from '@angular/common';
import { MessagingService } from "./services/messaging.service";
import { DatePipe } from '@angular/common';
import { ErrorStateMatcher } from '@angular/material/core';
import { WtErrorStateMatcher } from './services/error.interceptor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPayPalModule } from 'ngx-paypal';
import { AngularFireFunctionsModule, USE_EMULATOR } from '@angular/fire/compat/functions';
import { environment } from './../environments/environment';

const modules = [
  BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  AngularFireModule.initializeApp(FireBaseConfig),
  AngularFireMessagingModule,
  AngularFireDatabaseModule,
  AngularFirestoreModule,
  AngularFireAuthModule,
  AngularFireFunctionsModule,
  HttpClientModule,
  MatInputModule,
  MatButtonModule,
  MatTableModule,
  MatExpansionModule,
  MatTooltipModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatIconModule,
  MatFormFieldModule,
  MatDialogModule,
  MatPaginatorModule,
  MatSortModule,
  MatCardModule,
  MatSnackBarModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  ImageCropperModule,
  NgxPayPalModule,
];


@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    BasicComponent,
    ShowinfoComponent,
    StartComponent,
    ShowpageComponent,
    AssistantComponent,
    CarouselStartComponent,
    LoginComponent,
    PasswordResetComponent,
    RegisterComponent,
    RegisterSellerComponent,
    LogoutComponent,
    ArticleShowAddInfoListComponent,
    ArticleDetailComponent,
    ArticleListComponent,
    NewsletterDetailComponent,
    NewsletterListComponent,
    EmailconfirmComponent,
    NewsletterUserDetailComponent,
    NewsletterUserListComponent,
    SellerDetailComponent,
    ImageCropperComponent,
  ],
  imports: [...modules],
  exports: [],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [MessagingService, AsyncPipe,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorStateMatcher, useClass: WtErrorStateMatcher },
    { provide: USE_EMULATOR, useValue: environment.production ? undefined : ['localhost', 5001] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
