<!-- Begin Page Content -->
<main class="mt-1 pt-4">
    <div class="container dark-grey-text mt-5">
        <!--Navbar-->
        <nav class="navbar navbar-expand-lg navbar-dark mdb-color mt-3 mb-1">
            <span class="text-white">
                <a class="nav-link" (click)="clickCancel()"><i class="fas fa-reply"></i></a>
            </span>
            <!-- Collapse button -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicNav" aria-controls="basicNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Collapsible content -->
            <div class="collapse navbar-collapse" id="basicNav">
                <!-- Links -->
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link" (click)="clickUpdate(o)"><i class="fas fa-check"></i> Speichern <span *ngIf="waiting_action" class="spinner-border spinner-border-sm mr-1"></span>
                            <span class="sr-only">(current)</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- Collapsible content -->
        </nav>
        <!--/.Navbar-->

        <div class="row">
            <div class="col-lg-8 mb-4">
                <div *ngIf="o" class="card">
                    <!--Card content-->
                    <div class="card-body">
                        <form [formGroup]="i" class="wt-form">
                            <fieldset class="border p-2">
                                <legend class="w-auto text-muted"><small>Shop</small></legend>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Shopname" name="o.shopname" [(ngModel)]="o.shopname" formControlName="shopname">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.shopname.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Email" name="o.email" [(ngModel)]="o.email" formControlName="email">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.email.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Web" name="o.web" [(ngModel)]="o.web" formControlName="web">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.web.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Unternehmenform" name="o.companyform" [(ngModel)]="o.companyform" formControlName="companyform">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.companyform.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Steuernummer" name="o.taxnumber" [(ngModel)]="o.taxnumber" formControlName="taxnumber">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.taxnumber.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Impressum</mat-label>
                                    <textarea matInput placeholder="Impressum" name="o.imprint" [(ngModel)]="o.imprint" formControlName="imprint" required></textarea>
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.imprint.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <mat-label>Beschreibung</mat-label>
                                    <textarea matInput placeholder="Beschreibung .." name="o.description" [(ngModel)]="o.description" formControlName="description"></textarea>
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.description.hasError('required')">
                                        {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Suchbegriffe" name="o.keywords" [(ngModel)]="o.keywords" formControlName="keywords">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.keywords.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Option</mat-label>
                                    <mat-select formControlName="option" [(ngModel)]="o.option" name="o.option" matNativeControl required>
                                        <mat-option *ngFor="let option of options" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.option.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>

                            </fieldset>

                            <fieldset class="border p-2">
                                <legend class="w-auto text-muted"><small>Rechnungsadresse</small></legend>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Vorname" name="o.firstname" [(ngModel)]="o.firstname" formControlName="firstname">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.firstname.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Nachname" name="o.lastname" [(ngModel)]="o.lastname" formControlName="lastname">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.lastname.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Strasse" name="o.street" [(ngModel)]="o.street" formControlName="street">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.street.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Hausnummer" name="o.house" [(ngModel)]="o.house" formControlName="house">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.house.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="PLZ" name="o.zipcode" [(ngModel)]="o.zipcode" formControlName="zipcode">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.zipcode.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="City" name="o.city" [(ngModel)]="o.city" formControlName="city">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.city.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Stadtteil" name="o.district" [(ngModel)]="o.district" formControlName="district">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.district.hasError('required')">{{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Land" name="o.country" [(ngModel)]="o.country" formControlName="country">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.country.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Telefon" name="o.telefon" [(ngModel)]="o.telefon" formControlName="telefon">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.telefon.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                            </fieldset>


                            <fieldset class="border p-2">
                                <legend class="w-auto text-muted"><small>Lieferadresse</small></legend>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Vorname wie Rechnung" name="o.deliveryAddress.firstname" [(ngModel)]="o.deliveryAddress.firstname" formControlName="deliveryAddressFirstname">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.deliveryAddressFirstname.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Nachname wie Rechnung" name="o.deliveryAddress.lastname" [(ngModel)]="o.deliveryAddress.lastname" formControlName="deliveryAddressLastname">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.deliveryAddressLastname.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Strasse" name="o.deliveryAddress.street" [(ngModel)]="o.deliveryAddress.street" formControlName="deliveryAddressStreet">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.deliveryAddressStreet.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Hausnummer" name="o.deliveryAddress.house" [(ngModel)]="o.deliveryAddress.house" formControlName="deliveryAddressHouse">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.deliveryAddressHouse.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="PLZ" name="o.deliveryAddress.zipcode" [(ngModel)]="o.deliveryAddress.zipcode" formControlName="deliveryAddressZipcode">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.deliveryAddressZipcode.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="City" name="o.deliveryAddress.city" [(ngModel)]="o.deliveryAddress.city" formControlName="deliveryAddressCity">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.deliveryAddressCity.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Stadtteil" name="o.deliveryAddress.district" [(ngModel)]="o.deliveryAddress.district" formControlName="deliveryAddressDistrict">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.deliveryAddressDistrict.hasError('required')">{{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Land" name="o.deliveryAddress.country" [(ngModel)]="o.deliveryAddress.country" formControlName="deliveryAddressCountry">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.deliveryAddressCountry.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="wt-full-width">
                                    <input matInput placeholder="Telefon" name="o.deliveryAddress.telefon" [(ngModel)]="o.deliveryAddress.telefon" formControlName="deliveryAddressTelefon">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.deliveryAddressTelefon.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                            </fieldset>


                        </form>

                        <fieldset class="border p-2">
                            <legend class="w-auto text-muted"><small>Erweiterung</small></legend>
                            <ul class="list-group mb-1 z-depth-1">
                                <li class="list-group-item d-flex justify-content-between lh-condensed">
                                    <small class="text-muted my-0">
                                    <mat-form-field>
                                        <input matInput placeholder="Key" name="companyInfo.key"
                                            [(ngModel)]="companyInfo.key">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input matInput placeholder="Value" name="companyInfo.value"
                                            [(ngModel)]="companyInfo.value">
                                    </mat-form-field>
                                    <button mat-icon-button (click)="clickAddOption(companyInfo);">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </small>
                                    <small class="text-muted"></small>
                                </li>
                                <ng-container *ngIf="o.infos">
                                    <li *ngFor="let obj of o.infos" class="list-group-item d-flex justify-content-between lh-condensed">
                                        <small class="text-muted my-0">
                                        <i class="fas fa-trash-alt fakelink" (click)="clickRemoveOption(obj);"></i>
                                        &nbsp;&nbsp; {{obj.key}}
                                    </small>
                                        <small class="text-muted">
                                        {{obj.value}}
                                    </small>
                                    </li>
                                </ng-container>
                            </ul>
                        </fieldset>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <mat-spinner *ngIf="loading_file"></mat-spinner>
                <div *ngIf="!loading_file" class="card p-4 text-center">
                    <div class="mb-1" *ngIf="o && o.img && o?.img[1] !=''"><img src="{{o.img[1]}}" class="img-fluid" alt=""></div>
                    <div class="mb-1 p-2">
                        <label *ngIf="o?.img[1] ==''" for="input-file" class="fakelink">
                            <span class="badge blue mr-1"><i class="fas fa-upload"></i> Bild </span>
                        </label>
                        <input #fileNameInput *ngIf="o?.img[1] ==''" hidden type="file" accept="image/*" multiple="false" id="input-file" (click)="fileNameInput.value = null" (change)="fileChangeEvent($event);" />
                        <label *ngIf="o?.img[1] !=''" for="delete-file" class="fakelink" (click)="moveImageToTrash(o.img[1])">
                            <span  class="badge red mr-1"><i class="fas fa-trash"></i> Bild </span>
                    </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<!-- /.container-fluid -->