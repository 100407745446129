import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { SellerService } from './seller.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardSeller implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private sellerService: SellerService,
  ) { }

  async canActivate(route: any, state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.authService.getUser();
    let loggedIn = !!user;
    if (!loggedIn) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
    } else {
      const seller = await this.sellerService.getSeller(user?.uid);
      if (seller.length < 1) {
        loggedIn = false;
      }

    }

    return loggedIn;
  }

}
