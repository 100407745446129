<!-- Begin Page Content -->
<main class="mt-1 pt-4">
    <div class="container dark-grey-text mt-5">
        <!--Navbar-->
        <nav *ngIf="o" class="navbar navbar-expand-lg navbar-dark mdb-color mt-3 mb-1">
            <span class="text-white">
                <a class="nav-link" (click)="clickCancel()"><i class="fas fa-reply"></i></a>
            </span>
            <!-- Collapse button -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicNav" aria-controls="basicNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Collapsible content -->
            <div class="collapse navbar-collapse" id="basicNav">
                <!-- Links -->
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link" (click)="clickUpdate()"><i class="fas fa-check"></i> Speichern <span *ngIf="waiting_action" class="spinner-border spinner-border-sm mr-1"></span>
                            <span class="sr-only">(current)</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="clickDelete()"><i class="fas fa-trash"></i> Löschen <span *ngIf="waiting_action" class="spinner-border spinner-border-sm mr-1"></span></a>
                    </li>
                </ul>
            </div>
            <!-- Collapsible content -->
        </nav>
        <!--/.Navbar-->

        <div class="row" *ngIf="o">
            <div class="col-lg-8 mb-4">
                <!--Card-->
                <div class="card">
                    <!--Card content-->
                    <div class="card-body">
                        <form [formGroup]="i" class="wt-form">
                            <fieldset class="border p-2">
                                <legend class="w-auto text-muted"><small>Artikel</small></legend>
                                <mat-form-field>
                                    <input matInput placeholder="Nr." name="o.nr" [(ngModel)]="o.nr" formControlName="nr">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.nr.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="Artikelname" name="o.articlename" [(ngModel)]="o.articlename" formControlName="articlename">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.articlename.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Beschreibung</mat-label>
                                    <textarea matInput placeholder="Beschreibung" name="o.description" [(ngModel)]="o.description" formControlName="description" required></textarea>
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.description.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="Bildbeschreibung Textoben" name="o.img_top_description" [(ngModel)]="o.img_top_description" formControlName="img_top_description">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.img_top_description.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="Bildbeschreibung Textunten" name="o.img_bottom_description" [(ngModel)]="o.img_bottom_description" formControlName="img_bottom_description">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.img_bottom_description.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="Gruppierung" name="o.group" [(ngModel)]="o.group" formControlName="group">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.group.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Kategorie</mat-label>
                                    <mat-select formControlName="category" [(ngModel)]="o.category" name="o.category" matNativeControl required>
                                        <mat-option *ngFor="let category of categorys" [value]="category">
                                            {{category}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.category.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="Suchbegriff" name="o.searchkey" [(ngModel)]="o.searchkey" formControlName="searchkey">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.searchkey.hasError('required')"> {{t.erro0001}} </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Status</mat-label>
                                    <mat-select formControlName="state" [(ngModel)]="o.state" name="o.state" matNativeControl required>
                                        <mat-option *ngFor="let state of states" [value]="state">
                                            {{state}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.state.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="number" matInput placeholder="Min. Bestellmenge" name="o.min_order_quantities" [(ngModel)]="o.min_order_quantities" formControlName="min_order_quantities">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.min_order_quantities.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="number" matInput placeholder="Max. Bestellmenge" name="o.max_order_quantities" [(ngModel)]="o.max_order_quantities" formControlName="max_order_quantities">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="f.max_order_quantities.hasError('required')"> {{t.erro0001}}
                                    </mat-error>
                                </mat-form-field>
                            </fieldset>
                        </form>

                        <fieldset class="border p-2">
                            <legend class="w-auto text-muted"><small>Position</small></legend>

                            <div class="row">
                                <div class="col-lg-2">
                                    <mat-form-field>
                                        <input matInput placeholder="PositionNr." name="optionNr" [(ngModel)]="option.nr" required>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field>
                                        <input matInput placeholder="Beschreibung / Größe / Farbe / Variante" name="optionDescription" [(ngModel)]="option.description" required>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2">
                                    <mat-form-field>
                                        <input type="number" matInput placeholder="Preis" name="optionPrice" [(ngModel)]="option.price" required min="0">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2">
                                    <button mat-icon-button (click)="clickAddOption(option);">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>

                            <div *ngIf="o.options" class="row">
                                <ng-container *ngFor="let obj of o.options; let i = index;">
                                    <div class="col-lg-2">
                                        <mat-form-field>
                                            <input matInput placeholder="Nr." name={{i}} [(ngModel)]="obj.nr" [value]="obj.nr">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6">
                                        <mat-form-field>
                                            <input matInput placeholder="Beschreibung" name={{i}} [(ngModel)]="obj.description" [value]="obj.description">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <mat-form-field>
                                            <input type="number" matInput placeholder="Preis" name={{i}} [(ngModel)]="obj.price" [value]="obj.price">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <button mat-icon-button (click)="clickRemoveOption(obj);">
                                            <mat-icon>remove</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </fieldset>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <mat-spinner *ngIf="loading_file"></mat-spinner>
                <div *ngIf="!loading_file" class="card p-4 text-center">
                    <div class="mb-1" *ngIf="o && o.img && o?.img[1] !=''"><img src="{{o.img[1]}}" class="img-fluid" alt=""></div>
                    <div class="mb-1 p-2">
                        <label *ngIf="o?.img[1] ==''" for="input-file" class="fakelink">
                            <span class="badge blue mr-1"><i class="fas fa-upload"></i> Bild </span>
                        </label>
                        <input #fileNameInput *ngIf="o?.img[1] ==''" hidden type="file" accept="image/*" multiple="false" id="input-file" (click)="fileNameInput.value = null" (change)="fileChangeEvent($event);" />
                        <label *ngIf="o?.img[1] !=''" for="delete-file" class="fakelink" (click)="moveImageToTrash(o.img[1])">
                                <span  class="badge red mr-1"><i class="fas fa-trash"></i> Bild </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<!-- /.container-fluid -->