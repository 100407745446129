import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { text1s } from '../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';



@Component({
	selector: 'login.component',
	templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
	loginForm: UntypedFormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private alertService: AlertService,
		private titleService: Title,
		private metaTagService: Meta,
	) {

	}

	ngOnInit() {
		// get return url from route parameters or default to '/'
		this.returnUrl = decodeURIComponent(this.route.snapshot.queryParams['returnUrl'] || '');
		this.loginForm = this.formBuilder.group({
			email: ['', Validators.required],
			password: ['', Validators.required]
		});

	}

	// convenience getter for easy access to form fields
	get f() { return this.loginForm.controls; }

	async onSubmit() {
		this.submitted = true;

		// reset alerts on submit
		this.alertService.clear();

		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}
		this.loading = true;
		await this.authenticationService.signInRegular(this.f.email.value, this.f.password.value)
			.then(
				async data => {
					if (data) {
						setTimeout(() => {
							this.router.navigateByUrl(this.returnUrl);
						}, 4000);

					}
				}).catch((err) => {
					this.alertService.snackbar(text1s.erro0001);
					this.loading = false;
				});
	}

	clickCrtCustomerAccount(){
		this.router.navigate(['a/register'], { queryParams: { returnUrl: this.returnUrl } });
	}

	clickCrtSellerAccount(){
		this.router.navigate(['a/registerseller'], { queryParams: { returnUrl: this.returnUrl } });
	}

	clickPwReset(){
		this.router.navigate(['a/passwordreset'], { queryParams: { returnUrl: this.returnUrl } });
	}

	setMeta(): void {
		this.titleService.setTitle(`${text1s.domain} | Anmelden`);

		this.metaTagService.updateTag(
			{ name: 'robots', content: 'noindex, nofollow' }
		);
		this.metaTagService.updateTag(
			{ name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
		);
		this.metaTagService.updateTag(
			{ name: 'keywords', content: `${text1s.domain} Anmelden` }
		);
		this.metaTagService.updateTag(
			{ name: 'description', content: `${text1s.domain} Anmelden` }
		);
	}
}