import { Injectable } from "@angular/core";
import * as firebase from "firebase/compat/app";
import { Observable, BehaviorSubject } from "rxjs";
import { User } from "../models/user";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";
import { filter, map, first } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import CommonFunctions from "../shared/common.functions"

export const ANONYMOUS_USER: User = new User();

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    public session = '';

    token: any = window.location.hostname;
    private subject = new BehaviorSubject<User>(new User());

    user$: Observable<User> = this.subject
        .asObservable()
        .pipe(filter((user) => !!user));

    isLoggedIn$: Observable<boolean> = this.user$.pipe(
        map((user) => {
            return !!user.emailId;
        })
    );

    isLoggedOut$: Observable<boolean> = this.isLoggedIn$.pipe(
        map((isLoggedIn) => !isLoggedIn)
    );

    isAdmin$: Observable<boolean> = this.user$.pipe(
        map((user) => !!user.isAdmin)
    );

    constructor(
        private firebaseAuth: AngularFireAuth,
        private router: Router,
        private http: HttpClient
    ) { this.userInit(); }

    getCurrentUser() {
        return this.subject;
    }

    logout() {
        CommonFunctions.deleteCookie(this.token);
        this.subject.next(ANONYMOUS_USER);
        localStorage.removeItem(this.token);
        try {
            this.firebaseAuth.signOut().then(
                () => {
                    setTimeout(() => {
                        this.router.navigate(['/e/logout']);
                    }, 1000);
                });
        } catch (error) {
            console.log(error);
        }
    }

    createUserWithEmailAndPassword(emailID: string, password: string) {
        return this.firebaseAuth.createUserWithEmailAndPassword(emailID.trim(), password)
            .then((res) => {
                this.userInit();
                return res;
            });
    }

    sendPasswordResetEmail(email: string) {
        return this.firebaseAuth.sendPasswordResetEmail(email);
    }

    updateCurrentUser(user: firebase.default.User) {
        return this.firebaseAuth.updateCurrentUser(user);
    }

    signInRegular(email: string, password: string) {
        return this.firebaseAuth.signInWithEmailAndPassword(email, password)
            .then((res) => {
                this.userInit();
                return res;
            });
    }

    signInWithGoogle() {
        return this.firebaseAuth.signInWithPopup(
            new firebase.default.auth.GoogleAuthProvider()
        );
    }

    async userInit(): Promise<any> {
        const user = await this.getUser();
        if (user && user.emailVerified) {
            const output = this.setUser(user);
            this.subject.next(output as User);
            localStorage.setItem(this.token, user.uid);
            CommonFunctions.setCookie(this.token, user.uid);
        }
        return user;
    }

    getUser(): Promise<any> {
        return this.firebaseAuth.authState.pipe(first()).toPromise();
    }

    setUser(user: any): any {
        return {
            userName: user.displayName || "Anonymous",
            emailId: user.email,
            phoneNumber: user.phoneNumber,
            avatar: user.photoURL,
            isAdmin: false,
            uid: user.uid,
            token: user.getIdToken()
        };
    }

    getIPAddress() {
        return this.http.get("https://api.ipify.org/?format=json");
    }

}