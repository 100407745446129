import { NgModule } from '@angular/core';
import { AuthGuardCustomer } from './services/auth.guard.customer';
import { AuthGuardSeller } from './services/auth.guard.seller';
import { Routes, RouterModule } from '@angular/router';
import { BasicComponent } from './components/basic.component';
import { StartComponent } from './components/start.component';
import { ShowpageComponent } from './components/showpage.component';
import { AssistantComponent } from './components/assistant.component';
import { LogoutComponent } from './components/logout.component';
import { LoginComponent } from './components/login.component';
import { RegisterComponent } from './components/register.component';
import { RegisterSellerComponent } from './components/registerSeller.component';
import { PasswordResetComponent } from './components/passwordReset.component';
import { ArticleListComponent } from './components/article-list.component';
import { ArticleDetailComponent } from './components/article-detail.component';
import { NewsletterUserListComponent } from './components/newsletterUser-list.component';
import { NewsletterUserDetailComponent } from './components/newsletterUser-detail.component';
import { NewsletterListComponent } from './components/newsletter-list.component';
import { NewsletterDetailComponent } from './components/newsletter-detail.component';
import { EmailconfirmComponent } from './components/emailconfirm.component';
import { SellerDetailComponent } from './components/seller-detail.component';

const routes: Routes = [
  {
    path: '', component: BasicComponent,
    children:
      [
        { path: '', component: StartComponent },
        { path: 'assistent', component: AssistantComponent },
        { path: 'speisekarte/:searchkey/:shopname/:city/:zipcode/:uid', component: ShowpageComponent },
        { path: 'agb/:searchkey/:shopname/:city/:zipcode/:uid', component: ShowpageComponent },
        { path: 'emailconfirm/:emailconfirm', component: EmailconfirmComponent },
     /* { path: 'seller-list/:city', component: SellerListComponent }, */
      ]
  },
  {
    path: 'a', component: BasicComponent,
    children:
      [
      /*  { path: 'register', component: RegisterComponent }, */
      /*  { path: 'registerseller', component: RegisterSellerComponent }, */
        { path: 'passwordreset', component: PasswordResetComponent }, 
        { path: 'login', component: LoginComponent },
        { path: '**', redirectTo: '/speisekarte/geschenkgutschein/NGOAN%20Streetfood/Würzburg/97084/LyvEwG6QadVAJPAojtpxcb695dy1', pathMatch: 'full' }
      ]
  },
  {
    path: 'c', component: BasicComponent,
    children:
      [
      /*  { path: 'articleshow-list/:shopname/:city/:zipcode/:uid', component: ArticleShowListComponent }, */
      /*  { path: 'customer-detail', component: CustomerDetailComponent, canActivate: [AuthGuardCustomer] }, */
      /*  { path: 'checkout/:shopname/:city/:zipcode/:uid', component: CheckoutComponent, canActivate: [AuthGuardCustomer] }, */
      /*  { path: 'checkoutSeller/:shopname/:city/:zipcode/:uid', component: CheckoutSellerComponent, canActivate: [AuthGuardCustomer] }, */
        { path: 'article-list', component: ArticleListComponent, canActivate: [AuthGuardSeller] },
        { path: 'article-detail', component: ArticleDetailComponent, canActivate: [AuthGuardSeller] },
        { path: 'newsletterUser-list', component: NewsletterUserListComponent, canActivate: [AuthGuardSeller] },
        { path: 'newsletterUser-detail', component: NewsletterUserDetailComponent, canActivate: [AuthGuardSeller] },
        { path: 'newsletter-list', component: NewsletterListComponent, canActivate: [AuthGuardSeller] },
        { path: 'newsletter-detail', component: NewsletterDetailComponent, canActivate: [AuthGuardSeller] },
      /*  { path: 'order-list', component: OrderListComponent, canActivate: [AuthGuardCustomer] }, */
      /*  { path: 'contract-list', component: ContractListComponent, canActivate: [AuthGuardSeller] }, */
        { path: 'seller-detail', component: SellerDetailComponent, canActivate: [AuthGuardSeller] },
        { path: '**', redirectTo: '/speisekarte/geschenkgutschein/NGOAN%20Streetfood/Würzburg/97084/LyvEwG6QadVAJPAojtpxcb695dy1', pathMatch: 'full' }
      ]
  },
  {
    path: 'e', component: BasicComponent,
    children:
      [
        { path: 'logout', component: LogoutComponent },
        { path: '**', redirectTo: '', pathMatch: 'full' }
      ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
