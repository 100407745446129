import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { text1s } from '../../environments/environment';


@Component({
	selector: 'passwordReset.component',
	templateUrl: 'passwordReset.component.html'
})
export class PasswordResetComponent implements OnInit {
	registerForm: UntypedFormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private alertService: AlertService
	) {

	}

	ngOnInit() {
		this.registerForm = this.formBuilder.group({
			email: ['', Validators.required],
		});
		// get return url from route parameters or default to '/'
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
	}

	// convenience getter for easy access to form fields
	get f() { return this.registerForm.controls; }

	async onSubmit() {
		this.submitted = true;

		// reset alerts on submit
		this.alertService.clear();

		// stop here if form is invalid
		if (this.registerForm.invalid) {
			return;
		}
		this.loading = true;
		await this.authenticationService.sendPasswordResetEmail(this.f.email.value)
			.then(
				() => {
					this.alertService.success(text1s.info0002);
					setTimeout(() => {
						this.router.navigate([this.returnUrl]);
					}, 4000);

				}
			).catch((err) => {
				this.alertService.error(err.message);
				this.loading = false;
			});
	}
}
