import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UUID } from 'angular2-uuid';
import CommonFunctions from '../shared/common.functions';


export interface ImageCropper {
	titel: string;
	imageChangedEvent: any;
	croppedImage: any;
	aspectRatio: any;
}

@Component({
	selector: 'image-cropper-component',
	templateUrl: './image-cropper.component.html',
	styleUrls: ['../styles.css']
})
export class ImageCropperComponent {

	constructor(
		public dialogRef: MatDialogRef<ImageCropperComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ImageCropper) { }

	onNoClick(): void {
		this.dialogRef.close();
	}

	createFileFromData(data): any {
		if (data.croppedImage) {
			return CommonFunctions.convertBase64ToFile(data.croppedImage, UUID.UUID());
		}
		return null;
	}


	imageCropped(event: ImageCroppedEvent) {
		this.data.croppedImage = event.base64;
	}

	imageLoaded(image?: HTMLImageElement) {

	}

	cropperReady() {
		// cropper ready
	}

	loadImageFailed() {
		// show message
	}

}