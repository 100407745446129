import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { text1s } from '../../environments/environment';
import { Seller } from '../models/seller';
import { SellerService } from '../services/seller.service';

@Component({
	selector: 'registerSeller.component',
	templateUrl: 'registerSeller.component.html'
})
export class RegisterSellerComponent implements OnInit {
	registerForm: UntypedFormGroup;
	loading = false;
	submitted = false;
	returnUrl = 'c/seller-detail';

	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private alertService: AlertService,
		private s: SellerService,
	) {

	}

	ngOnInit() {
		this.registerForm = this.formBuilder.group({
			displayName: ['', Validators.required],
			email: ['', Validators.required],
			password: ['', Validators.required]
		});
	}

	// convenience getter for easy access to form fields
	get f() { return this.registerForm.controls; }

	async onSubmit() {
		this.submitted = true;

		// reset alerts on submit
		this.alertService.clear();

		// stop here if form is invalid
		if (this.registerForm.invalid) {
			return;
		}
		this.loading = true;
		await this.authenticationService.createUserWithEmailAndPassword(this.f.email.value, this.f.password.value)
			.then(
				result => {
					if (!result.user?.emailVerified) {
						this.alertService.success(text1s.info0002);
						result.user?.sendEmailVerification();
						result.user?.updateProfile({
							displayName: this.f.displayName.value,
							photoURL: '',
						});
						setTimeout(() => {
							this.createSeller(result);
							this.router.navigate([this.returnUrl]);
						}, 4000);

					}
				}).catch((err) => {
					this.alertService.error(err.message);
					this.loading = false;
				});
	}

	createSeller(i: any) {

		let seller = new Seller();
		seller.shopname = this.f.displayName.value;
		seller.description = this.f.displayName.value;
		seller.email = i.user.email;
		seller.uid = i.user.uid;

		this.s.add(Object.assign({}, seller));
	}
}
