import { Injectable } from '@angular/core';
import { map, first } from 'rxjs/operators';
import { throwError as _throw, of } from 'rxjs';
import { Customer } from '../models/customer';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthenticationService } from './authentication.service';
import { User } from '../models/user';
import { systemSettings } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomerService {

    objs: AngularFirestoreCollection<Customer>;
    obj: AngularFirestoreDocument<Customer>;
    selectedObj: Customer;
    private user: User;

    constructor(
        private firestore: AngularFirestore,
        private authService: AuthenticationService,
    ) { }

    setSelected(o: Customer) {
        this.selectedObj = o;
    }

    getSelected() {
        return of(this.selectedObj);
    }

    getCustomer(uid: String): Promise<any> {
        return this.firestore.collection(`${systemSettings.collectionPrefix}_customers`, ref => ref.where('uid', '==', uid))
            .snapshotChanges().pipe(first()).toPromise();
    }

    find(uid: String) {
        this.user = this.authService.getCurrentUser().getValue();
        if (uid == '') {
            uid = this.user.uid;
        }
        this.objs = this.firestore.collection(`${systemSettings.collectionPrefix}_customers`, ref => ref.where('uid', '==', uid));
        return this.objs.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                id: c.payload.doc.id,
                customername: c.payload.doc.data().customername,
                description: c.payload.doc.data().description,
                city: c.payload.doc.data().city,
                district: c.payload.doc.data().district,
                street: c.payload.doc.data().street,
                house: c.payload.doc.data().house,
                zipcode: c.payload.doc.data().zipcode,
                country: c.payload.doc.data().country,
                telefon: c.payload.doc.data().telefon,
                email: c.payload.doc.data().email,
                firstname: c.payload.doc.data().firstname,
                lastname: c.payload.doc.data().lastname,
                text: c.payload.doc.data().text,
                option: c.payload.doc.data().option,
                img: c.payload.doc.data().img,
                deliveryAddress: c.payload.doc.data().deliveryAddress,
                timestamp: c.payload.doc.data().timestamp,
                uid: c.payload.doc.data().uid,
            }))));
    }

    save(data: any) {
        this.user = this.authService.getCurrentUser().getValue();
        data.uid = this.user.uid;
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_customers`)
            .doc(data.id)
            .set(data);
    };

    add(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_customers`)
            .add(data);
    };

    delete(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_customers`)
            .doc(data.id)
            .delete();
    }

    sendmail(data: any) {
        this.firestore
            .collection("trigger-emails")
            .add(
                {
                    to: data.email,
                    message: {
                        subject: 'NGOAN-Streetfood',
                        html: '<p>Guten Tag ' + data.firstname + ' ' + data.lastname + ', </p>' +
                            '<p> Vielen Dank für Ihre Bestellung</p>' +
                            '<p> NGOAN-Streetfood Team</p>'
                    },
                }
            );
    };
}