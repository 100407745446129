import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar as MatSnackBar, MAT_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatSnackBarRef as MatSnackBarRef } from '@angular/material/snack-bar';

import { AlertService } from '../services/alert.service';

@Component({ selector: 'alertComponent', templateUrl: 'alert.component.html' })
export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService, private _snackBar: MatSnackBar) { }

    ngOnInit() {
        this.subscription = this.alertService.getAlert()
            .subscribe(message => {
                switch (message && message.type) {
                    case 'success':
                        message.cssClass = 'alert alert-success';
                        this.message = message;
                        break;
                    case 'error':
                        message.cssClass = 'alert alert-danger';
                        this.message = message;
                        break;
                    case 'snackbar':
                        this.openSnackBar(message.text);
                        break;
                }
            });
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, 'Ok', {
            duration: 3000,
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
