export const FireBaseConfig = {
  apiKey: "AIzaSyDgvOqREj5n3FT2kPYBoOlRo9vnacaYUL4",
  authDomain: "shopservice-app.firebaseapp.com",
  databaseURL: "https://shopservice-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "shopservice-app",
  storageBucket: "shopservice-app.appspot.com",
  messagingSenderId: "158498520868",
  appId: "1:158498520868:web:bede46b56b81c666363db1",
  measurementId: "G-ZRQY7N40EP"
};
