export interface Article {
  id: string;
  nr: string;
  articlename: string;
  description: string;
  img_top_description: string;
  img_bottom_description: string;
  group: string;
  category: string;
  searchkey: string;
  state: string;
  options: ArticleOption[];
  min_order_quantities: number;
  max_order_quantities: number;
  img: string[];
  timestamp: Date;
  seller_shopname: string;
  seller_uid: string;
}

export interface ArticleOption {
  nr: string;
  description: string;
  price: number;
};

export enum ArticleCategory {
  product = 'Produkt',
  service = 'Dienstleistung',
  info = 'Information',
  any = 'Sontiges'
};
