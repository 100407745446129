<main class="mt-1 pt-4">
    <div class="container dark-grey-text mt-5">
        <!-- Outer Row -->
        <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-12 col-md-9">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-3 d-none d-lg-block"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <p class="h4 text-gray-900 mb-4">Anmelden</p>
                                    </div>
                                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="user">
                                        <div class="form-group">
                                            <input type="text" formControlName="email" class="form-control form-control-user" id="email" aria-describedby="emailHelp" value="" placeholder="Email Address..." [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email ist erforderlich
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="password" formControlName="password" class="form-control form-control-user" id="password" value="" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password ist erforderlich
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <a (click)="onSubmit()" class="btn btn-primary btn-user btn-block"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Login</a>
                                        </div>
                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        <a class="badge mdb-color lighten-4" (click)="clickCrtCustomerAccount()">Nutzerkonto erstellen</a> &nbsp;
                                        <a class="badge mdb-color lighten-4" (click)="clickCrtSellerAccount()">Verkäuferkonto eröffnen </a> &nbsp;
                                        <a class="badge mdb-color lighten-4" (click)="clickPwReset()">Password vergessen ?</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 d-none d-lg-block"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>