<!--Grid row-->
<div class="row d-flex justify-content-center wow fadeIn">

    <!--Grid column-->
    <div class="col-md-6 text-center">

        <h4 class="my-4 h4">bis bald..</h4>

        <p>NGOAN Streetfood</p>

    </div>
    <!--Grid column-->

</div>
<!--Grid row-->

<!--Grid row-->
<div class="row wow fadeIn">

    <!--Grid column-->
    <div class="col-lg-4 col-md-12 mb-4">

        <img src="../../assets/img/pageimg/bg.jpg" class="img-fluid img-profile-300" alt="">

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-4 col-md-6 mb-4">

        <img src="../../assets/img/pageimg/bg1.jpg" class="img-fluid img-profile-300" alt="">

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-4 col-md-6 mb-4">

        <img src="../../assets/img/pageimg/bg2.jpg" class="img-fluid img-profile-300" alt="">

    </div>
    <!--Grid column-->

</div>
<!--Grid row-->