import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { text1s } from '../../environments/environment';

@Component({
    selector: 'logout',
    templateUrl: './logout.component.html',
    styleUrls: ['../styles.css']
})
export class LogoutComponent implements OnInit, AfterViewInit {

    constructor(
        private titleService: Title,
        private metaTagService: Meta,
    ) { };
    ngOnInit() {
        this.setMeta();
    }

    ngAfterViewInit() {
    }

    setMeta(): void {
        this.titleService.setTitle(`${text1s.domain} | Abmelden`);

        this.metaTagService.updateTag(
            { name: 'robots', content: 'noindex, nofollow' }
        );
        this.metaTagService.updateTag(
            { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
        );
        this.metaTagService.updateTag(
            { name: 'keywords', content: `${text1s.domain} Abmelden` }
        );
        this.metaTagService.updateTag(
            { name: 'description', content: `${text1s.domain} Abmelden` }
        );
    }
}