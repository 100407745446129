import { UUID } from 'angular2-uuid';
import { DeliveryAddress } from '../models/customer';

export class Seller {

  id: string;
  shopname: string;
  firstname: string;
  lastname: string;
  city: string;
  district: string;
  street: string;
  house: string;
  zipcode: string;
  country: string;
  telefon: string;
  email: string;
  web: string;
  description: string;
  keywords: string;
  option: string;
  img: string[];
  companyform: string;
  taxnumber: string;
  imprint: string;
  infos?: {
    key: string;
    value: string;
  }[];
  deliveryAddress: DeliveryAddress;
  timestamp: Date;
  uid: string;

  constructor(
    id = UUID.UUID(),
    shopname = '',
    firstname = '',
    lastname = '',
    city = '',
    district = '',
    street = '',
    house = '',
    zipcode = '',
    country = '',
    telefon = '',
    email = '',
    web = '',
    description = '',
    keywords = '',
    option = '',
    img = ['shop.jpg', '../../assets/img/pageimg/shop.jpg'],
    companyform = "",
    taxnumber = "",
    imprint = "",
    infos = new Array(),
    deliveryAddress = {
      firstname: '',
      lastname: '',
      city: '',
      district: '',
      street: '',
      house: '',
      zipcode: '',
      country: '',
      telefon: '',
    } as DeliveryAddress,
    timestamp = new Date(),
    uid = '',
  ) {
    this.id = id;
    this.shopname = shopname;
    this.firstname = firstname;
    this.lastname = lastname;
    this.city = city;
    this.district = district;
    this.street = street;
    this.house = house;
    this.zipcode = zipcode;
    this.country = country;
    this.telefon = telefon;
    this.email = email;
    this.web = web;
    this.description = description;
    this.keywords = keywords;
    this.option = option;
    this.img = img;
    this.companyform = companyform;
    this.taxnumber = taxnumber;
    this.imprint = imprint;
    this.infos = infos;
    this.deliveryAddress = deliveryAddress;
    this.timestamp = timestamp;
    this.uid = uid;
  }

};

export enum SellerOption {
  shop = 'Shop',
  market = 'Market',
  shop_market = 'Shop & Market',
};
