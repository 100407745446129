import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'articleShowAddInfo-list',
    templateUrl: './articleShowAddInfo-list.component.html',
    styleUrls: ['../styles.css']
})
export class ArticleShowAddInfoListComponent implements OnInit {

    constructor() { };

    ngOnInit() {
    }

}
