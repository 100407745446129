import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { throwError as _throw, of } from 'rxjs';
import { NewsletterUser } from '../models/newsletterUser';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthenticationService } from './authentication.service';
import { User } from '../models/user';
import { systemSettings } from '../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NewsletterUserService {

    objs: AngularFirestoreCollection<NewsletterUser>;
    obj: AngularFirestoreDocument<NewsletterUser>;
    selectedObj: NewsletterUser;
    filterValue: string;
    private user: User;

    constructor(
        private firestore: AngularFirestore,
        private authService: AuthenticationService,
    ) {
        this.authService.getCurrentUser().pipe(first()).subscribe(user => this.user = user);
    }

    setSelected(o: NewsletterUser) {
        this.selectedObj = o;
    }

    getSelected() {
        return of(this.selectedObj);
    }

    findID(id: string) {
        this.objs = this.firestore.collection(`${systemSettings.collectionPrefix}_newsletterUsers`, ref => ref.where('id', '==', id));
        return this.objs.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                id: c.payload.doc.id,
                email: c.payload.doc.data().email,
                state: c.payload.doc.data().state,
                seller_uid: c.payload.doc.data().seller_uid,
                timestamp: c.payload.doc.data().timestamp,
            }))));
    }

    findUID(uid: string) {
        if (uid == '') {
            uid = this.user.uid;
        }
        this.objs = this.firestore.collection(`${systemSettings.collectionPrefix}_newsletterUsers`, ref => ref.where('seller_uid', '==', uid).orderBy("email"));
        return this.objs.snapshotChanges().pipe(map(changes => changes
            .map(c => ({
                id: c.payload.doc.id,
                email: c.payload.doc.data().email,
                state: c.payload.doc.data().state,
                seller_uid: c.payload.doc.data().seller_uid,
                timestamp: c.payload.doc.data().timestamp,
            }))));
    }

    save(data: any) {
        if (data.seller_uid == '') {
            data.seller_uid = this.user.uid;
        }
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_newsletterUsers`)
            .doc(data.id)
            .set(data);
    };

    add(data: any) {
        if (data.seller_uid == '') {
            data.seller_uid = this.user.uid;
        }
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_newsletterUsers`)
            .add(data);
    };

    delete(data: any) {
        this.firestore
            .collection(`${systemSettings.collectionPrefix}_newsletterUsers`)
            .doc(data.id)
            .delete();
    }
}