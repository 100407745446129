// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true
};

export const systemSettings: Record<string, string> =
{
  'operator': 'info@ngoan-streetfood.de',
  'collectionPrefix': 'ngoan',
  'storagePrefix': 'ngoan',
  'cookieName': '_ngoan_streetfood_de_session',
  'seller_uid': 'LyvEwG6QadVAJPAojtpxcb695dy1'
};

export const text1s: Record<string, string> =
{
  'titel': 'NGOAN | Echte vietnamesische Küche in Würzburg Heidingsfeld',
  'domain': 'ngoan-streetfood.de',
  'chgdate': new Date().toISOString().slice(0, 10),
  'erro0001': 'Die Eingabe ist ungültig',
  'info0001': 'Der Vorgang ist erfolgreich abgeschlossen',
  'info0002': 'Bitte prüfen Sie Ihre Email...',
  'erro0002': 'Der Vorgang ist fehlerhaft beendet',
  'link_agb1': '/agb/datenschutz/NGOAN%20Streetfood/Würzburg/97084/LyvEwG6QadVAJPAojtpxcb695dy1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
