<div class="text-center my-auto ml-auto">
    <span>
        <alertComponent></alertComponent>
    </span>
</div>
<nav class="navbar fixed-top navbar-expand-lg navbar-dark mdb-color darken-3 scrolling-navbar">
    <div class="container">
        <a class="navbar-brand waves-effect" [routerLink]="['']" data-toggle="collapse" data-target=".navbar-collapse.show">
            <img class="img-profile" src="../../assets/img/pageimg/ngoan.png"><strong class="yellow-text"> NGOAN
                Streetfood </strong>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link waves-effect" (click)="clickShowPage('vietnam-streetfood');">Speisekarte</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link waves-effect" (click)="clickShowPage('vegetarische-speisen');">Vegetarisch</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link waves-effect" (click)="clickShowPage('banhmi');">Banh Mi</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link waves-effect" (click)="clickShowPage('fischsauce');">Fischsauce</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link waves-effect" (click)="clickShowPage('ueberuns');">Über NGOAN</a>
                </li>
            </ul>
            <ul class="navbar-nav nav-flex-icons">
                <li class="nav-item dropdown no-arrow">
                    <a *ngIf="seller || customer" class="nav-link" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span *ngIf="customer" class="mr-2 d-none d-lg-inline small">{{customer.customername}}</span>
                        <span *ngIf="seller" class="mr-2 d-none d-lg-inline small">{{seller.shopname}}</span>
                        <img *ngIf="customer" class="img-profile" src="{{customer.img[1]}}">
                        <img *ngIf="seller" class="img-profile" src="{{seller.img[1]}}">
                    </a>
                    <div class="dropdown-menu dropdown-menu shadow animated--grow-in" aria-labelledby="userDropdown">

                        <a *ngIf="seller" class="dropdown-item" [routerLink]="['/c/contract-list']">
                            <sub>
                                <mat-icon>format_list_numbered</mat-icon>
                            </sub> Auftrags</a>

                        <a *ngIf="seller" class="dropdown-item" [routerLink]="['/c/article-list']">
                            <sub>
                                <mat-icon>article</mat-icon>
                            </sub> Artikels</a>

                        <a *ngIf="seller" class="dropdown-item" [routerLink]="['/c/newsletterUser-list']">
                            <sub>
                                <mat-icon>supervised_user_circle</mat-icon>
                            </sub> Users</a>

                        <a *ngIf="seller" class="dropdown-item" [routerLink]="['/c/newsletter-list']">
                            <sub>
                                <mat-icon>email</mat-icon>
                            </sub>Newsletter</a>

                        <a class="dropdown-item" [routerLink]="['/c/order-list']">
                            <sub>
                                <mat-icon>list_alt</mat-icon>
                            </sub> Bestellung</a>

                        <a *ngIf="customer" class="dropdown-item" [routerLink]="['/c/customer-detail']">
                            <sub>
                                <mat-icon>account_box</mat-icon>
                            </sub> Account</a>

                        <a *ngIf="seller" class="dropdown-item" [routerLink]="['/c/seller-detail']">
                            <sub>
                                <mat-icon>storefront</mat-icon>
                            </sub> Account</a>

                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" (click)="logout()">
                            <sub>
                                <mat-icon>login</mat-icon>
                            </sub> Abmelden</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<router-outlet></router-outlet>

<div class="container">

    <div class="text-center pt-2 rounded" style="background-color: #f5f5f5;">
        <h2 class="h2 grey-text"><strong>Hungrig auf etwas Köstliches?</strong></h2>
        <p  class="py-2">
            <a href="https://onlinebestellung.ngoan-streetfood.de/shop/article/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="accent"><mat-icon>alarm</mat-icon> JETZT Vorbestellen</button></a>
        </p>
    </div>

    <div class="anchor-fix-5rem" id='feedback'></div>

    <div class="wow fadeIn">
        <div class="card h-100 mt-4">
            <div class="card-body">
                <div class="row text-left text-muted">
                    <div class="col">
                        <p><b>Hilf uns besser zu werden!</b></p>
                        <form [formGroup]="i" #formDirective="ngForm" class="wt-form">
                            <div class="row text-left mt-4">
                                <div class="col">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Anregungen, Wünsche, Kritik...</mat-label>
                                        <textarea matInput placeholder="Anregungen, Wünsche, Kritik" name="busine_text" [(ngModel)]="busine_text" formControlName="busine_text"></textarea>
                                        <mat-hint></mat-hint>
                                        <mat-error *ngIf="f.busine_text.hasError('required')"> Bitte geben Sie Ihren Text hier ein.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row text-center">
                                <div class="col"> <button mat-raised-button (click)="clickSendInfo()">Absenden</button></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">

    <div class="anchor-fix-5rem" id='newsletter'></div>

    <div class="wow fadeIn">
        <div class="card h-100 mt-4">
            <div class="card-body">
                <div class="row text-left text-muted">
                    <div class="col">
                        <h5><strong>NGOAN´s Newsletter</strong></h5>
                        <p>Melden Sie sich jetzt für unseren Newsletter an und verpassen Sie nie wieder unsere neuesten Angebote.</p>
                        <form [formGroup]="iNewsletter" #formDirectiveNewsletter="ngForm" class="wt-form">
                            <div class="row text-left mt-4">
                                <div class="col">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Email</mat-label>
                                        <input matInput placeholder="Email" name="busine_newsletter" [(ngModel)]="busine_newsletter" formControlName="busine_newsletter">
                                        <mat-hint></mat-hint>
                                        <mat-error *ngIf="fNewsletter.busine_newsletter.hasError('email')"> Bitte geben Sie Ihren Email hier ein.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row text-center">
                                <div class="col"> <button mat-raised-button (click)="clickSendNewsletter()">Anmelden</button></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="page-footer text-center font-small mt-4 wow fadeIn">
    <div class="py-4 mdb-color darken-2">
        <span class="mr-4">
            <a href="https://twitter.com/NgoanStreetfood/" target="_blank">
                <img src="https://onlinebestellung.ngoan-streetfood.de/assets/img/socialmedia/twitter.png" width="23px" height="23px">
            </a>
        </span>
        <span class="mr-4">
            <a href="https://g.page/NGOAN-Streetfood" target="_blank">
                <img src="https://onlinebestellung.ngoan-streetfood.de/assets/img/socialmedia/google.png" width="23px" height="23px">
            </a>
        </span>
        <span class="mr-4">
            <a href="https://www.facebook.com/NgoanStreetfood/" target="_blank">
                <img src="https://onlinebestellung.ngoan-streetfood.de/assets/img/socialmedia/facebook.png" width="23px" height="23px">
            </a>
        </span>
        <span class="mr-4">
            <a href="https://www.instagram.com/ngoan.streetfood/" target="_blank">
                <img src="https://onlinebestellung.ngoan-streetfood.de/assets/img/socialmedia/instagram.png" width="23px" height="23px">
            </a>
        </span>
        <span class="mr-4">
            <a href="https://www.youtube.com/channel/UCf-ZHtq4eRwkdnr-YLJMAsA/" target="_blank">
                <img src="https://onlinebestellung.ngoan-streetfood.de/assets/img/socialmedia/youtube.png" width="23px" height="23px">
            </a>
        </span>
        <span class="mr-1">
            <a href="https://www.pinterest.de/ngoan_streetfood/" target="_blank">
                <img src="https://onlinebestellung.ngoan-streetfood.de/assets/img/socialmedia/pinterest.png" width="23px" height="23px">
            </a>
        </span>
    </div>
    <div class="pt-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-left">
                    <b class="section-heading">
                        <a href="https://g.page/NGOAN-Streetfood" target="_blank">
                            <strong>NGOAN Streetfood </strong> <br>
                            <i class="fas fa-map-marker-alt"></i> Klosterstr. 15<br>
                            97084 Würzburg Heidingsfeld<br>
                            &nbsp;<br>
                        </a>
                        <a href="tel:093168084329" target="_blank"><i class="fa fa-phone"> &nbsp; (0931) 6808 4329</i></a><br>
                        <i class="far fa-envelope"></i> &nbsp; info(a)ngoan-streetfood.de<br>
                        &nbsp;<br>
                        <strong>Öffnungszeiten:</strong><br>
                        Mo. bis Fr. von 11:00 bis 20:30 Uhr.<br>
                    </b>

                    <b class="section-heading">
                        &nbsp;<br>
                        <strong><a class="mr-1" (click)="clickShowPageAGB('karriere');"> Karriere </a> <a class="mr-1"
                                (click)="clickShowPageAGB('datenschutz');"> Datenschutz </a> <a class="mr-1" (click)="clickShowPageAGB('impressum');"> Impressum </a> </strong><br>
                        &nbsp;<br>
                    </b>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-copyright py-3">
        © <a [routerLink]="['']"> NGOAN Streetfood </a>
    </div>
</footer>