<carouselStartComponent></carouselStartComponent>

<main *ngIf="articles">

    <div class="container" *ngFor="let article of getGroupArticles('Willkommen')">
        <div class="wow fadeIn text-center">
            <h2 class="h2 grey-text mt-4 mb-2"><img class="mr-1" style="border-radius: 50%;" src="assets/img/pageimg/ngoan.png" width="40" height="40" /> <strong>{{article.articlename}}</strong></h2>
            <div class="card h-100">
                <div class="card-body">
                    <div class="row text-muted">
                        <div class="col-lg-5">
                            <img src="../../assets/img/pageimg/ngoan1.jpg" class="img-responsive-x" alt="NGOAN-Streetfood">
                        </div>
                        <div class="col-lg-7">
                            <p class="text-center"><b>Bei NGOAN - da schmeckt man vietnamesisch!</b></p>
                            <div class="text-muted text-left" [innerHTML]="article.description"></div>
                            <p>
                                <span class="move-left-right">👉 </span> <a href="https://restaurant.jetzt/shop/article/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="accent"><mat-icon>alarm</mat-icon> JETZT Vorbestellen</button></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">

        <section class="mt-3 wow fadeIn">
            <div class="row">
                <div class="col-lg-4 mb-2 text-center fakelink" *ngFor="let article of getGroupArticles('NGOAN Vietnam Streetfood')" (click)="clickShowFoodmenu(article.img_top_description);">
                    <div class="card h-100">
                        <h4 class=" blue-text card-header"><strong>{{article.articlename}}</strong></h4>
                        <div class="card-body">
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2 pulse" alt="{{article.img_top_description}}">
                            <p class="text-muted" [innerHTML]="article.description"></p>
                        </div>
                        <div class="card-footer ">
                            <p class="blue-text text-center mt-3"><strong>Jetzt mehr erfahren!</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="mt-2 wow fadeIn">
            <div class="row">

                <div class="col-lg-4 mb-4 text-center" *ngFor="let article of getGroupArticles('Vegetarische Speisen')">
                    <div class="card fakelink h-100" (click)="clickShowFoodmenu('vegetarische-speisen');">
                        <h4 class="blue-text  card-header"><strong>Vegetarisch</strong></h4>
                        <div class="card-body">
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2 pulse" alt="{{article.articlename}}">
                            <h6 class="mb-2 text-muted"><strong>{{article.articlename}}</strong></h6>
                            <p class="text-muted" [innerHTML]="article.description"></p>
                        </div>
                        <div class="card-footer ">
                            <p class="blue-text text-center mt-3"><strong>Jetzt mehr entdecken!</strong></p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 mb-4 text-center" *ngFor="let article of getGroupArticles('Fischsauce')">
                    <div class="card fakelink h-100" (click)="clickShowFoodmenu('fischsauce');">
                        <h4 class="blue-text card-header"><strong>NGOAN´s Fischsauce</strong></h4>
                        <div class="card-body">
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2 pulse" alt="{{article.articlename}}">
                            <h6 class="mb-2 text-muted"><strong>{{article.articlename}}</strong></h6>
                            <p class="text-muted" [innerHTML]="article.description"></p>
                        </div>
                        <div class="card-footer ">
                            <p class="blue-text text-center mt-3"><strong>Jetzt mehr entdecken!</strong></p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 mb-4 text-center" *ngFor="let article of getGroupArticles('Spezial der Woche')">
                    <div class="card fakelink h-100" (click)="clickShowFoodmenu('vietnam-streetfood');">
                        <h4 class="blue-text card-header"><span class="move-up-down">👍</span><strong>Spezial der Woche</strong></h4>
                        <div class="card-body">
                            <img src="{{article.img[1]}}" class="mx-auto img-profile-300 mb-2 pulse" alt="{{article.articlename}}">
                            <h6 class="mb-2 text-muted"><strong>{{article.articlename}}</strong></h6>
                            <p class="text-muted" [innerHTML]="article.description"></p>
                        </div>
                        <div class="card-footer ">
                            <p class="blue-text text-center mt-3"><strong>Jetzt mehr entdecken!</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <hr class="my-4">

    </div>
</main>