import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenerateService, Generate } from '../services/generate.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { marked } from 'marked';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { UUID } from 'angular2-uuid';

@Component({
    selector: 'assistant',
    templateUrl: './assistant.component.html',
    styleUrls: ['../styles.css']
})
@AutoUnsubscribe()
export class AssistantComponent implements OnInit, OnDestroy {

    @AutoUnsubscribe()
    activatedRoute$;
    userPrompt: string = '';
    sessionId: string = '';
    responses: Generate[] = [];
    isSending: boolean = false;
    private checkStatusSubscription: Subscription;

    buttonPrompts: string[] = [
        "Typisch vietnamesisch?",
        "Was ist Phở?",
        "Beliebtestes Gericht?",
        "Scharfe Speisen?",
        "Gibt's was Feines?",
        "Bánh Mì lecker?",
        "Vietnamesisches Bier & Kaffee?",
        "Vegetarische Optionen?",
        "Gesunde Gerichte?",
        "Kundensupport möglich?",
        "Wer ist NGOAN?"
    ];

    constructor(
        private generateService: GenerateService,
        private titleService: Title,
        private metaTagService: Meta,
    ) {
        const storedSessionId = sessionStorage.getItem('NGOANStreetfoodSessionIdAssitant');
        if (!storedSessionId) {
            this.sessionId = UUID.UUID();
            sessionStorage.setItem('NGOANStreetfoodSessionIdAssitant', this.sessionId);
        } else {
            this.sessionId = storedSessionId;
        }        
     };

    ngOnInit() {
        if (this.sessionId) {
            this.checkStatusSubscription = this.generateService.findSessionId(this.sessionId).subscribe( o => {
                if (o && o.length > 0) {
                    this.responses = o.map(item => item.data);
                    const noCompletedItem = o.find(item => item.data.status.state != 'COMPLETED');
                    if (noCompletedItem) {
                        this.isSending = true;
                    } else {
                        this.isSending = false;  
                    }
                }
            })
        }
        this.setMeta();
    }

    setMarked(text: string) {
        return marked(text);
    }
    
    submitPromptWithQuetion(prompt: string) {
        this.userPrompt = prompt;
        this.submitPrompt();
    }
    
    submitPrompt() {
        if (this.userPrompt.trim() !== '') {
            this.isSending = true;
            const newGenerate: Generate = {
                sessionId: this.sessionId,
                prompt: this.userPrompt,
                response: '',
                createTime: null,
                status: {
                    completeTime: null,
                    startTime: null,
                    state: '',
                    updateTime: null
                }
            };
            this.userPrompt = '';
            setTimeout(() => {
                this.generateService.createData(newGenerate)
                    .then(docRef => {
                    })
                    .catch(error => {
                        console.error('Error creating document:', error);
                    });
            }, 1000); 
        }
    }

    setMeta(): void {
        this.titleService.setTitle(`NGOAN-Streetfood | Assistent`);
        this.metaTagService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    }

    ngOnDestroy() {
        if (this.checkStatusSubscription) {
            this.checkStatusSubscription.unsubscribe();
        }
    }

}
