<!--Carousel Wrapper-->
<div id="carousel-show-1z" class="carousel slide carousel-fade pt-4" data-ride="carousel">

    <!--Indicators-->
    <!-- <ol class="carousel-indicators">
        <li data-target="#carousel-show-1z" data-slide-to="0" class="active"></li>
        <li data-target="#carousel-show-1z" data-slide-to="1"></li>
        <li data-target="#carousel-show-1z" data-slide-to="2"></li>
    </ol> -->
    <!--/.Indicators-->

    <!--Slides-->
    <div class="carousel-inner" role="listbox">

        <!--First slide-->
        <div class="carousel-item active">
            <div class="view" style="background-image: url('../../assets/img/pageimg/bg.jpg'); background-repeat: no-repeat; background-size: cover;">

                <!-- Mask & flexbox options-->
                <div class="mask rgba-black-strong d-flex justify-content-center align-items-center">
                    <!-- Content -->
                    <div class="text-center white-text mx-5 wow fadeIn">
                        <img *ngIf="seller" src="{{seller.img[1]}}" class="img-profile-150 mt-5" alt="{{seller.shopname}}">
                        <mat-spinner *ngIf="!seller" class="img-profile-150 mt-5"></mat-spinner>
                        <h3 class="mt-3">
                            <strong>Authentische Straßenküche Vietnams</strong>
                        </h3>
                        <p class="mt-3">
                            <a href="https://restaurant.jetzt/shop/reserve/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="primary"><mat-icon>today</mat-icon> Jetzt Reservieren &nbsp;</button></a>
                        </p>
                        <p>
                            <a href="https://restaurant.jetzt/shop/article/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="primary"><mat-icon class="pulse">alarm</mat-icon> Jetzt Vorbestellen</button></a>
                        </p>
                        <p>
                            <a href="tel:093168084329" target="_blank"><button mat-raised-button color="primary"><mat-icon class="wiggle">phone</mat-icon> (0931) 6808 4329 &nbsp;</button></a><br>
                            <small><b>Mo. - Fr. von 11:00 - 20:30 Uhr</b></small>
                        </p>
                    </div>
                    <!-- Content -->
                </div>
                <!-- Mask & flexbox options-->

            </div>
        </div>
        <!--/First slide-->

        <!--Second slide-->
        <div class="carousel-item">
            <div class="view" style="background-image: url('../../assets/img/pageimg/bg1.jpg'); background-repeat: no-repeat; background-size: cover;">

                <!-- Mask & flexbox options-->
                <div class="mask rgba-black-strong d-flex justify-content-center align-items-center">
                    <!-- Content -->
                    <div class="text-center white-text mx-5 wow fadeIn">
                        <img *ngIf="seller" src="{{seller.img[1]}}" class="img-profile-150 mt-5" alt="{{seller.shopname}}">
                        <mat-spinner *ngIf="!seller" class="img-profile-150 mt-5"></mat-spinner>
                        <h3 class="mt-3">
                            <strong>Authentische Straßenküche Vietnams</strong>
                        </h3>
                        <p class="mt-3">
                            <a href="https://restaurant.jetzt/shop/reserve/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="primary"><mat-icon>today</mat-icon> Jetzt Reservieren &nbsp;</button></a>
                        </p>
                        <p>
                            <a href="https://restaurant.jetzt/shop/article/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="primary"><mat-icon class="pulse">alarm</mat-icon> Jetzt Vorbestellen</button></a>
                        </p>
                        <p>
                            <a href="tel:093168084329" target="_blank"><button mat-raised-button color="primary"><mat-icon class="wiggle">phone</mat-icon> (0931) 6808 4329 &nbsp;</button></a><br>
                            <small><b>Mo. - Fr. von 11:00 - 20:30 Uhr</b></small>
                        </p>
                    </div>
                    <!-- Content -->
                </div>
                <!-- Mask & flexbox options-->

            </div>
        </div>
        <!--/Second slide-->

        <!--Third slide-->
        <div class="carousel-item">
            <div class="view" style="background-image: url('../../assets/img/pageimg/bg2.jpg'); background-repeat: no-repeat; background-size: cover;">

                <!-- Mask & flexbox options-->
                <div class="mask rgba-black-strong d-flex justify-content-center align-items-center">
                    <!-- Content -->
                    <div class="text-center white-text mx-5 wow fadeIn">

                        <img *ngIf="seller" src="{{seller.img[1]}}" class="img-profile-150 mt-5" alt="{{seller.shopname}}">
                        <mat-spinner *ngIf="!seller" class="img-profile-150 mt-5"></mat-spinner>
                        <h3 class="mt-3">
                            <strong>Authentische Straßenküche Vietnams</strong>
                        </h3>
                        <p class="mt-3">
                            <a href="https://restaurant.jetzt/shop/reserve/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="primary"><mat-icon>today</mat-icon> Jetzt Reservieren &nbsp;</button></a>
                        </p>
                        <p>
                            <a href="https://restaurant.jetzt/shop/article/97084/W%C3%BCrzburg/NGOAN%20Streetfood/lz9PD8X7ZoSzp7a4WaM96jgxB3g1" target="_blank"><button mat-raised-button color="primary"><mat-icon class="pulse">alarm</mat-icon> Jetzt Vorbestellen</button></a>
                        </p>
                        <p>
                            <a href="tel:093168084329" target="_blank"><button mat-raised-button color="primary"><mat-icon class="wiggle">phone</mat-icon> (0931) 6808 4329 &nbsp;</button></a><br>
                            <small><b>Mo. - Fr. von 11:00 - 20:30 Uhr</b></small>
                        </p>
                    </div>
                    <!-- Content -->
                </div>
                <!-- Mask & flexbox options-->

            </div>
        </div>
        <!--/Third slide-->

    </div>
    <!--/.Slides-->

    <!--Controls-->
    <a class="carousel-control-prev" href="#carousel-show-1z" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-show-1z" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
    <!--/.Controls-->

</div>
<!--/.Carousel Wrapper-->
<div *ngIf="seller?.infos">
    <span *ngFor="let info of seller.infos">
        <div *ngIf="info.key == 'infotext'" class="container text-muted px-3 pt-3">
            <i class="fa fa-info-circle blink_me red-text pr-1"></i><strong><b>{{info.value}}</b></strong>
        </div>
    </span>
</div>