import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { text1s, systemSettings } from '../../environments/environment';
import { SellerService } from '../services/seller.service';
import { ArticleService } from '../services/article.service';
import { Article, ArticleCategory } from '../models/article';
import { State } from './article-detail.component';
import { Seller } from '../models/seller';
import { first } from 'rxjs/operators';

@Component({
    selector: 'start',
    templateUrl: './start.component.html',
    styleUrls: ['../styles.css']
})
export class StartComponent implements OnInit, AfterViewInit {

    city = '';
    seller: Seller;
    articles: Article[];
    articleCategory = ArticleCategory;

    constructor(
        private titleService: Title,
        private metaTagService: Meta,
        private router: Router,
        protected activatedRoute: ActivatedRoute,
        private sellerService: SellerService,
        private articleService: ArticleService,
    ) {

    };

    async getUid() {
        return await this.sellerService.getSellerUid(systemSettings.operator);
    }

    clickShowFoodmenu(searchkey: string) {
        this.router.navigate(['speisekarte', searchkey, this.seller.shopname, this.seller.city, this.seller.zipcode, this.seller.uid], {fragment: searchkey});
    }

    getGroupArticles(group: string) {
        return this.articles.filter(article => article.group === group && article.state === State.active);
    }

    clickNewsletterInfo() {
        let el = document.getElementById('newsletterinfo');
        el.scrollIntoView({ behavior: 'smooth' });
    }

    ngOnInit() {
        this.getUid().then(sellerAdminUid => {
            this.articleService.findUID(sellerAdminUid[0].uid).pipe(first()).subscribe(articles => {
                this.articles = articles;
            });
            this.sellerService.find(sellerAdminUid[0].uid).pipe(first()).subscribe(seller_data => {
                this.seller = seller_data[0];
                if (this.seller && text1s) {
                    this.setMeta(this.seller);
                }
            });
        });
    }

    ngAfterViewInit() {
    }

    setMeta(sellerInfo: Seller): void {

        this.titleService.setTitle(`NGOAN | Echte vietnamesische Küche`);

        this.metaTagService.updateTag(
            { name: 'robots', content: 'index, follow' }
        );
        this.metaTagService.updateTag(
            { name: 'date', content: `${text1s.chgdate}`, scheme: 'YYYY-MM-DD' },
        );
        this.metaTagService.updateTag(
            { name: 'keywords', content: `NGOAN Streetfood Asiatisch Vietnamesisches Restaurant Würzburg Heidingsfeld Bánh Mì Tofu Spezialitäten traditionellen Küche authentisch Speisekarte Geschenkgutschein Vegetarisches Menü Fischsauce Takeaway Essen Mitnehmen Online Bestellen` }
        );
        this.metaTagService.updateTag(
            { name: 'description', content: `Erleben Sie die Aromen der vietnamesischen Küche! Bei NGOAN servieren wir Ihnen nur die besten Spezialitäten aus unserer traditionellen Küche.` }
        );
    }

}